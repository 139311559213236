<template>
    <div class="document-block document-block--editable" v-bind:class="{'border-red' : componentError}">
        <reorder :position="position" />
        <remove :position="position" />
        <blockToolbar :view="view" @view-change="onViewChange" />

        <div class="guide-article__block image-section" v-show="inPreview">
            <div class="guide-article__block-content">
                <img v-if="hasImage" v-bind:src="component.image" v-bind:alt="component.imageDescription">
                <span class="text-gray-text-light italic text-xs block mt-12">{{component.imageDescription}}</span>
            </div>
        </div>

        <form v-show="inEdit">
            <error :has-error="hasError" :message="errorMessage" />

            <label for="image_file">{{$lang('selectImage')}} *</label>
            <input type="file" id="image_file" name="image_file" ref="imageFile" accept="image/png, image/jpeg" v-if="!hasImage" @change="onSetFile" class="pt-12 mb-16"/>

            <div class="relative max-w-sm mb-16 border border-dashed border-gray-border" v-if="hasImage">
                <img v-if="hasImage" v-bind:src="component.image" v-bind:alt="component.imageDescription">

                <button class="absolute top-16 right-24 sm:right-16 z-10" type="button" @click="onRemoveImage">
                    <i class="fal fa-times text-2xl sm:text-lg text-gray-text-light"></i>
                </button>
            </div>

            <label for="image_description">{{$lang('imageCaption')}}</label>
            <div class="relative">
                <input type="text" id="image_description" name="image_description" v-model="component.imageDescription" class="mb-16 pr-80">
                <span class="absolute right-8 top-16 text-gray-border">{{descriptionLength}} / 200</span>
            </div>

            <button type="button" class="btn sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0" @click="onUploadImage" :disabled="loading">{{$lang('add')}}</button>
        </form>

        <loader :is-loading="loading" />
    </div>
</template>

<script>
  import { store } from '../store/store';
  import vueMixins from '../../vue-mixins';
  import reorder from '../parts/part-reorder';
  import remove from '../parts/part-remove';
  import loader from '../parts/part-component-loader';
  import error from '../parts/part-component-error';
  import blockToolbar from '../parts/part-block-toolbar';

  export default {
    name: "componentImage",
    mixins: [vueMixins],
    data() {
      return {
        file: false,
        loading: false,
        errorMessage: '',
        view: 'edit',
        postID: store.state.document.id,
      }
    },
    props: {
      component: Object,
      position: Number,
      componentError: Boolean,
    },
    components: {
      loader,
      reorder,
      remove,
      error,
      blockToolbar,
    },
    computed: {
      hasImage: function() {
        return this.component.image.length > 0;
      },
      inPreview: function() {
        return this.view === 'preview';
      },
      inEdit: function() {
        return this.view === 'edit';
      },
      descriptionLength: function() {
        return this.component.imageDescription.length;
      },
      hasError: function() {
        return this.errorMessage.length > 0;
      },
    },
    watch: {
      componentError: function (newValue) {
        if (newValue) {
          this.validate();
        }
      },
      descriptionLength: function(newValue) {
        if (newValue > 200) {
          this.component.imageDescription = this.component.imageDescription.substr(0, 200);
        }
      }
    },
    methods: {
      onViewChange(view) {
        this.view = view;
      },
      onSetFile: function() {
        // Reset error message
        this.errorMessage = '';
        this.component.image = '';
        let file = this.$refs.imageFile.files.length > 0    ?   this.$refs.imageFile.files[0]    :   false;

        if (file && ['image/png', 'image/jpg'].indexOf(file.type) !== -1) {
          this.file = file;
        } else {
          this.file = false;
        }
      },
      onUploadImage: function() {
        this.loading = true;

        if (this.validate()) {
          let formData = new FormData();

          if (this.file) {
            formData.append('file', this.file, this.file.name);
          }

          formData.append('image_description', this.component.imageDescription );
          formData.append('image_id', this.component.attachmentID );
          formData.append('action', 'mk_private_upload_image');

          store.actions.uploadFile( formData ).always((response, textStatus) => {
            this.loading = false;

            if (textStatus === 'success' && response.id) {
              this.component.image = response.image;
              this.component.imageDescription = response.imageDescription;
              this.component.attachmentID = response.id;
            }

            if (textStatus === 'success') {
              this.onViewChange('preview');
            }

            if (textStatus !== 'success') {
              this.errorMessage = typeof response.responseJSON === 'undefined' ? this.$lang('genericError') : response.responseJSON.message;
            }
          });
        } else {
          this.loading = false;
        }
      },
      onRemoveImage() {
        this.file = '';
        this.component.image = '';
      },
      validate() {
        let valid = this.file || this.hasImage;

        if (!valid) {
          this.errorMessage = this.$lang('imageSelectionError');
          this.inEdit();
        } else {
          this.errorMessage = '';
          this.component.isValid = true;
        }

        return valid;
      }
    },
    mounted: function () {
      // Show preview if we have a image
      this.$nextTick(function () {
        // Give this component instance a unique id
        if (this.component.componentID === false) {
          this.component.componentID = `flx_${this.postID}_${new Date().getTime()}`;
        }

        if (this.hasImage) {
          this.onViewChange('preview');
        }
      });
    }
  };
</script>

import $ from 'jquery';
import navWidget from '../components/navigation-widget';
import guide from '../components/guide';
import guideHighlight from '../components/guide-highlight';
import modalImage from '../components/modal-image';
import expandableCard from '../components/expandable-card';
import chooseDocument from '../components/modal-choose-document';
import bookmark from '../components/bookmark';

export default {
  components: [
    navWidget,
    guide,
    guideHighlight,
    modalImage,
    expandableCard,
    chooseDocument,
    bookmark,
  ],

  init() {
    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });
    this.countView();
  },

  addEvents() {
  },

  modifyDOM() {
  },

  countView() {
    $.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_increment_view_count',
        post_id: window.ajax_object.post_id,
        nonce: window.ajax_object.nonce,
      },
      dataType: 'json',
      method: 'POST',
    });
  },
};

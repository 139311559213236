import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.js-toggle-search-filters', this.searchFilterToggle.bind(this));
  },

  searchFilterToggle() {
    $('.search-results-filters').slideToggle();
  },
};

<template>
    <div class="document-block document-block--editable" v-bind:class="{'border-red' : componentError}">
        <reorder :position="position" />
        <remove :position="position" />
        <blockToolbar :view="view" @view-change="onViewChange" />

        <div class="document-block__content" v-show="inPreview">
            <h2 class="section-heading">{{component.title}}</h2>
            <div v-html="component.content"></div>
        </div>

        <form v-show="inEdit">
            <error :has-error="hasError" :message="errorMessage" />

            <label for="title">{{$lang('textTitle')}}</label>
            <div class="relative">
                <input type="text" id="title" name="title" v-model="component.title" class="mb-16 pr-80">
                <span class="absolute right-8 top-16 text-gray-border">{{titleLength}} / 200</span>
            </div>

            <div class="tiny mb-12 md:mb-24">
                <tiny-editor api-key="no-api-key" :id="'tiny-content' + position" v-model="component.content" :init="{
                    height: 300,
                    resize: false,
                    menubar: false,
                    content_css: contentCSS,
                    plugins: ['lists'],
                    toolbar: 'undo redo | ' +
                              'bold italic | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist | ' +
                              'removeformat',
                }"/>
            </div>

            <button type="button" class="btn sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0" @click="onSave('preview')">{{$lang('add')}}</button>
        </form>
    </div>
</template>

<script>
  import {store} from '../store/store';
  import vueMixins from '../../vue-mixins';
  import reorder from '../parts/part-reorder';
  import remove from '../parts/part-remove';
  import error from '../parts/part-component-error';
  import blockToolbar from '../parts/part-block-toolbar';
  import Editor from '@tinymce/tinymce-vue';
  //https://www.tiny.cloud/docs/integrations/vue/#tinymcevuejsintegrationquickstartguide

  export default {
    name: "componentText",
    mixins: [vueMixins],
    data() {
      return {
        loading: false,
        errorMessage: '',
        view: 'edit',
        postID: store.state.document.id,
        contentCSS: store.state.links.content_css,
      }
    },
    props: {
      component: Object,
      position: Number,
      componentError: Boolean,
    },
    components: {
      reorder,
      remove,
      error,
      blockToolbar,
      'tiny-editor': Editor,
    },
    computed: {
      hasContent() {
        return this.component.content.length > 0 && this.component.title.length > 0;
      },
      inPreview: function() {
        return this.view === 'preview';
      },
      inEdit: function() {
        return this.view === 'edit';
      },
      hasError: function() {
        return this.errorMessage.length > 0;
      },
      titleLength: function() {
        return this.component.title.length;
      },
    },
    watch: {
      componentError: function (newValue) {
        if (newValue) {
          this.validate();
        }
      },
      titleLength: function(newValue) {
        if (newValue > 200) {
          this.component.title = this.component.title.substr(0, 200);
        }
      }
    },
    methods: {
      onSave() {
        if (this.validate()) {
          this.onViewChange('preview');
        }
      },
      onViewChange(view) {
        this.view = view;
      },
      validate() {
        let valid = this.component.title.length > 0;

        if (!valid) {
          this.errorMessage = this.$lang('ComponentTitleError');
          this.onViewChange('edit');
        } else {
          this.errorMessage = '';
          this.component.isValid = true;
        }

        return valid;
      }
    },
    mounted: function () {
      // Show preview if we have a image
      this.$nextTick(function () {
        // Give this component instance a unique id
        if (this.component.componentID === false) {
          this.component.componentID = `flx_${this.postID}_${new Date().getTime()}`;
        }

        if (this.hasContent) {
          this.onViewChange('preview');
        }
      });
    }
  };
</script>

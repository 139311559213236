import $ from 'jquery';

export default {
  init() {
    this.$wrapper = $('.bookmarked-pages-nav');
    this.addEvents();
  },

  addEvents() {
    this.$wrapper.on('click', '.bookmarked-pages-nav__list-item', this.bookmarkFilter.bind(this));
  },

  bookmarkFilter({currentTarget}) {
    let $target = $(currentTarget);

    $('.bookmarked-pages-nav__list-item').removeClass('bookmarked-pages-nav__list-item--active');
    $target.addClass('bookmarked-pages-nav__list-item--active');

    this.filter($target.data('filter'));

    return false;
  },

  filter(key) {
    $('.js--bookmark-card-wrapper').find('.js--bookmark-card').each( function() {
      let data = $(this).data('taxonomy');

      if (key === 'all') {
        $(this).show();
      }
      else if (key === data) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  }
};

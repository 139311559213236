<template>
    <div class="border border-red bg-orange__light rounded p-12 flex items-center mb-12" v-if="error.hasError">
        <i class="fas fa-exclamation-circle text-orange text-2xl mr-8"></i>
        <span class="font-bold text-gray-dark-title text-sm">
            {{error.message}}
        </span>
    </div>
</template>

<script>
  import { store } from '../store/store';

  export default {
    name: "error",
    data() {
      return {
        error: store.state.error,
      }
    },
  };
</script>

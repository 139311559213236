import $ from 'jquery';
import Modal from './modal';
export default {
  init() {
    this.$loginModal = $('.modal-login-holder');
    this.addEvents();
    this.ModifyDOM();
  },

  addEvents() {
    $(document).on('click', '.js-go-to-login', this.viewLogin.bind(this));
    $(document).on('click', '.js-go-to-forgot-password', this.viewForgotPassword.bind(this));
    $(document).on('click', '.js-login-modal', this.showLoginModal.bind(this));
    $(document).on('submit', 'form[name="password_modal_login"]', this.loginUser.bind(this));
    $(document).on('submit', 'form[name="password_modal_reset"]', this.passwordReset.bind(this));
  },

  ModifyDOM() {
  },

  showLoginModal() {
    this.open();
    return false;
  },

  open() {
    const content = this.$loginModal.html();
    const opts = {
      classes: 'modal--login',
    };

    const modal = new Modal({
      ...opts,
      content,
    });
    modal.open();
  },

  viewLogin() {
    $('.modal__content, .modal__step').css('display', 'none');
    $('.modal__content .modal__step:first-of-type').css('display', 'block');

    $('.modal__content').fadeOut(550, function(){
      setTimeout(function() {
        $('.modal__content--login').fadeIn(300);
      },300);
    });
  },

  viewForgotPassword() {
    $('.modal__content').fadeOut(300, function() {
      setTimeout(function() {
        $('.modal__content--forgot-password').fadeIn(300);
      },300);
    });
  },

  loginUser(e) {
    e.preventDefault();
    let $form = $(e.target);
    let data = $form.serializeArray();
    let $errorWrapper = $('.js--login-failure');
    $errorWrapper.hide();
    $form.find('button').addClass('btn--loading');

    $.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_login_user',
        form_data: data,
      },
      dataType: 'json',
      method: 'POST',
    }).always((response) => {
      $form.find('button').removeClass('btn--loading');

      if (response.signon === true) {
        window.location.href = response.redirect;
      } else {
        $errorWrapper.html(response.error);
        $errorWrapper.show();
      }
    });
  },

  passwordReset(e) {
    e.preventDefault();
    let $form = $(e.target);
    let $errorWrapper = $('.js--password-reset-failure');
    $errorWrapper.hide();
    $form.find('button').addClass('btn--loading');

    $.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_password_reset',
        wp_nonce: $form.find('input[name="wp_nonce"]').val(),
        user_login: $form.find('input[name="user_login"]').val(),
      },
      dataType: 'json',
      method: 'POST',
    }).always((response) => {
      $form.find('button').removeClass('btn--loading');

      if (response.reset === true) {
        $('.js--password-reset').show();
        $form.hide();
      } else {
        $errorWrapper.html(response.error);
        $errorWrapper.show();
      }
    });
  },
};

import $ from 'jquery';
import Modal from './modal';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.js-image-modal', this.onLaunchModalClick.bind(this));
  },

  onLaunchModalClick() {
    this.create().then(modal => {
      modal.open();
    });
    return false;
  },

  fetchMarkup() {

    let imageID = event.target.hasAttribute('data-image-id') ? $(event.target).attr('data-image-id') : $(event.target).attr('href');
    const deferred = $.Deferred();
    const content = '<div class="modal__image"><img src="' + imageID + '"></div>';
    deferred.resolve(content);

    return deferred.promise();
  },

  create(opts) {
    const deferred = $.Deferred();
    const defaultOpts = {
      classes: 'modal--image',
    };

    this.fetchMarkup().then(content => {
      const modal = new Modal({
        ...defaultOpts,
        ...opts,
        content,
      });

      deferred.resolve(modal);
    }).catch(() => {
      deferred.reject();
    });

    return deferred.promise();
  },
};


export const store = typeof window.documentObject === 'undefined' ? {} : {
  state: {
    document: window.documentObject.document,
    links: window.documentObject.links,
    nonce: window.documentObject.nonce,
    metals: window.documentObject.metals,
    target_groups: window.documentObject.target_groups,
    status: {
      isDirty: false,
      isLoading: false,
      isValidating: false,
    },
    error: {
      hasError: false,
      message: '',
    },
    success: {
      hasSuccess: false,
      message: '',
    }
  },
  mutations: {
    validating(status) {
      store.state.status.isValidating = status;
    },
    dirty(status) {
      store.state.status.isDirty = status;
    },
    error(message, hasError) {
      store.state.error.message = message;
      store.state.error.hasError = hasError;
    },
    success(message, hasSuccess) {
      store.state.success.message = message;
      store.state.success.hasSuccess = hasSuccess;
    },
    loading(status) {
      store.state.status.isLoading = status;
    },
    moveContent(oldPos, newPos) {
      if (newPos > -1 && newPos < store.state.document.flexible_content.length) {
        // Remove
        let document = store.state.document.flexible_content.splice(oldPos, 1)[0];
        // Readd
        store.state.document.flexible_content.splice(newPos, 0, document);
      }
    },
    removeContent(pos) {
      store.state.document.flexible_content.splice(pos, 1);
    },
    addContent(pos, content) {
      if (pos === -1) {
        store.state.document.flexible_content.push(content);
      } else {
        store.state.document.flexible_content.splice(pos, 0, content);
      }
    },
    addBookSource() {
      store.state.document.document_sources.push({
        'sourceID': `src_${store.state.document.id}_${new Date().getTime()}`,
        'author1': {'last_name': '', 'first_name_initial': ''},
        'author2': {'last_name': '', 'first_name_initial': ''},
        'author3': {'last_name': '', 'first_name_initial': ''},
        'year': '',
        'title': '',
        'publisher': '',
        'publisher_city': '',
        'type': 'book',
        'isValid': false,
      });
    },
    addInternetSource() {
      store.state.document.document_sources.push({
        'sourceID': `src_${store.state.document.id}_${new Date().getTime()}`,
        'author1': {'last_name': '', 'first_name_initial': ''},
        'author2': {'last_name': '', 'first_name_initial': ''},
        'author3': {'last_name': '', 'first_name_initial': ''},
        'year': '',
        'title': '',
        'date': '',
        'url': '',
        'type': 'internet',
        'isValid': false,
      });
    },
    removeSource(idx) {
      store.state.document.document_sources.splice(idx, 1);
    }
  },
  actions: {
    saveDocument() {
      return jQuery.ajax({
        url: store.state.links.ajax,
        data: {
          action: 'mk_private_save_document',
          nonce: store.state.nonce,
          post: store.state.document,
        },
        dataType: 'json',
        method: 'POST',
      });
    },
    deleteDocument() {
      return jQuery.ajax({
        url: store.state.links.ajax,
        data: {
          action: 'mk_private_delete_document',
          nonce: store.state.nonce,
          post_id: store.state.document.id,
        },
        dataType: 'json',
        method: 'POST',
      });
    },
    deleteFile(id) {
      return jQuery.ajax({
        url: store.state.links.ajax,
        data: {
          action: 'mk_private_delete_attachment',
          nonce: store.state.nonce,
          attachment_id: id,
          post_id: store.state.document.id,
        },
        dataType: 'json',
        method: 'POST',
      });
    },
    uploadFile(formData) {
      formData.append('nonce', store.state.nonce);
      formData.append('post_id', store.state.document.id);

      return jQuery.ajax({
        url: store.state.links.ajax,
        data: formData,
        method: 'POST',
        processData: false,
        contentType: false,
      });
    }
  },
};

<template>
    <div class="border border-red bg-orange__light rounded p-12 flex items-center mb-12" v-if="hasError">
        <i class="fas fa-exclamation-circle text-orange text-2xl mr-8"></i>
        <span class="font-bold text-gray-dark-title text-sm">
            {{message}}
        </span>
    </div>
</template>

<script>
  export default {
    name: "componentError",
    props: {
      message: String,
      hasError: Boolean,
    },
    data() {
      return {
      }
    },
  };
</script>

import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.js-copy-text', this.copyText.bind(this));
  },

  copyText() {
    var range = document.createRange();
    range.selectNode(document.getElementById('text-copy'));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  },
};

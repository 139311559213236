<template>
    <div class="flex justify-center items-center absolute bottom-0 left-32 transform translate-y-1/2">
        <span @click.stop="setView('preview')" v-show="inEdit"
              class="h-40 w-40 rounded-full border-gray-border-light bg-white flex justify-center items-center text-gray-border border mr-12 cursor-pointer">
            <i class="far fa-eye"></i>
        </span>

        <span @click.stop="setView('edit')" v-show="inPreview"
              class="h-40 w-40 rounded-full border-gray-border-light bg-white flex justify-center items-center text-gray-border border mr-12 cursor-pointer">
            <i class="far fa-edit"></i>
        </span>
    </div>
</template>

<script>

  export default {
    name: "blockToolbar",
    data() {
      return {}
    },
    props: {
      view: String,
    },
    components: {},
    computed: {
      inPreview: function () {
        return this.view === 'preview';
      },
      inEdit: function () {
        return this.view === 'edit';
      },
    },
    watch: {},
    methods: {
      setView(view) {
        this.$emit('view-change', view);
      },
    },
  };
</script>

<template>
    <div class="rounded bg-white shadow py-24 p-32 pb-40 sm:mb-24 sm:max-w-3xl md:max-w-full mx-auto md:w-full">
        <h5 class="mb-24 font-sans">{{$lang('shareDocument')}}</h5>

        <label class="text-gray-text-dark mb-8 uppercase text-xxs font-bold block">{{$lang('link')}}</label>
        <div class="border border-gray-border-light rounded-lg justify-between flex items-center pr-12 mb-24">
            <div id="text-copy" class="text-copy h-40 truncate w-3/4 border-none text-xxs text-gray-text-light pr-0 pl-16 leading-5xl">{{links.post}}</div>
            <button class="js-copy-text text-orange text-tiny font-bold uppercase a">{{$lang('copy')}}</button>
        </div>

        <label for="targetGroup" class="text-gray-text-dark mb-8 uppercase text-xxs font-bold block">{{$lang('targetGroup')}}</label>
        <div class="mb-32">
            <select id="targetGroup" name="targetGroup" v-model="document.target_group">
                <option value="false">{{$lang('select')}}</option>
                <option v-for="(group, idx) in target_groups" :key="idx" :value="group">{{group}}</option>
            </select>
        </div>

        <label for="metal" class="text-gray-text-dark mb-8 uppercase text-xxs font-bold block">{{$lang('area')}}</label>
        <div class="mb-32">
            <select id="metal" name="metal" v-model="document.metal">
                <option value="false">{{$lang('select')}}</option>
                <option v-for="(metal, idx) in metals" :key="idx" :value="metal">{{metal}}</option>
            </select>
        </div>

        <label class="text-gray-text-dark mb-8 uppercase text-xxs font-bold block">{{$lang('visibility')}}</label>
        <div class="mb-32">
            <label class="switch">
                <input type="checkbox" v-model="document.is_public">
                <span class="switch__slider round"></span>
            </label>
            <span class="inline-block ml-8">{{$lang('shareOnMetallforum')}}</span>
        </div>

        <a :href="links.export" target="_blank" class="btn btn--transparent block font-normal mb-32 w-full">
            <i class="fal fa-arrow-to-bottom text-orange mr-16 text-md"></i> {{$lang('exportAsPdf')}}
        </a>
        <button class="mx-auto block hover:underline" @click="showDeleteDocumentModal">
            <i class="fas fa-trash mr-16 text-md"></i> {{$lang('deleteDocument')}}
        </button>
    </div>
</template>

<script>
  import vueMixins from '../../vue-mixins';
  import { store } from '../store/store';
  import DeleteModal from '../../../components/modal-delete-document';

  export default {
    name: "sidebar-settings",
    mixins: [vueMixins],
    data() {
      return {
        document: store.state.document,
        links: store.state.links,
        nonce: store.state.nonce,
        loading: store.state.loading,
        metals: store.state.metals,
        target_groups: store.state.target_groups,
      }
    },
    computed: {
    },
    methods: {
      showDeleteDocumentModal() {
        DeleteModal.open();
      },
      onDeleteDocument() {
        store.mutations.loading(true);

        store.actions.deleteDocument().always( (response, textStatus) => {
          if (textStatus === 'success') {
            store.mutations.success( this.$lang('deleteSuccess'), true );
            window.location.replace(this.links.my_documents);
          } else {
            store.mutations.error( this.$lang('deleteError'), true );
          }
          store.mutations.loading(false);
        });
      },
    },
    mounted: function () {
      document.querySelector('.site-backdrop').addEventListener('click', (e) => {
        if (typeof e.target.classList !== 'undefined' && e.target.classList.contains('js--delete-document')) {
          this.onDeleteDocument();
        }
      });
    },
  };
</script>

import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.expandable-card__toggle', this.toggleCard.bind(this));
  },

  toggleCard( {currentTarget} ) {
    $(currentTarget).toggleClass('expandable-card__toggle--active');
    $(currentTarget).next('.expandable-card__content').slideToggle();
  },
};

<template>
    <div>
        <h5 class="mb-24 font-sans">{{$lang('addMedia')}}</h5>

        <button @click="onAddBlock('text')" class="border-t border-gray-border-light py-24 w-full text-left hover:underline">
            <i class="far fa-text mr-24"></i> {{$lang('addText')}}
        </button>

        <button  @click="onAddBlock('image')" class="border-t border-gray-border-light py-24 w-full text-left hover:underline">
            <i class="fas fa-image mr-24"></i> {{$lang('addImage')}}
        </button>

        <button  @click="onAddBlock('movie')" class="border-t border-gray-border-light py-24 w-full text-left hover:underline">
            <i class="fab fa-youtube mr-24"></i> {{$lang('addMovie')}}
        </button>

        <button  @click="onAddBlock('document')" class="border-t border-gray-border-light py-24 w-full text-left hover:underline">
            <i class="fas fa-file mr-24"></i> {{$lang('addDocument')}}
        </button>
    </div>
</template>

<script>
  import vueMixins from '../../vue-mixins';

  export default {
    name: "tools",
    mixins: [vueMixins],
    data() {
      return {
      }
    },
    props: {
      position: Number,
    },
    computed: {
    },
    methods: {
      onAddBlock(block) {
        this.$emit('add-block', {type: block, position: this.position});
      },
    },
  };
</script>

import $ from 'jquery';

export default {
  init() {
    this.setVars();
    this.addEvents();
    this.modifyDom();
  },

  setVars() {
    this.heading = $('.section-heading');
    this.sectionHeading = $('.guide-toc');
  },

  addEvents() {
  },

  modifyDom() {
    if (this.heading.length > 0) {
      this.createToc();
    }

    $('.guide-article__save-document').hover(
      function () {
        $(this).closest('.guide-article__block').addClass('guide-article__block--hover');
      },
      function () {
        $(this).closest('.guide-article__block').removeClass('guide-article__block--hover');
      }
    );
  },

  createToc() {
    this.sectionHeading.removeClass('hidden');
    $.each(this.heading, function (index, value) {
      $(value).attr("id", index);
      $("#toc-container").append(
        "<li><a href=\"#" +
        index + "\">" + $(value).html() + "</a></li>"
      );
    });
  },
};

<template>
    <div class="w-full flex sm:inline-block sm:w-auto xsOnly:flex-wrap mt-24 sm:hidden">
        <a :href="preview" target="_blank"
           class="btn btn--transparent font-normal px-0 w-1/2 mr-12 xsOnly:w-full xsOnly:mr-0 xsOnly:mb-16">
            {{$lang('preview')}}
        </a>

        <button @click="oSave" class="btn sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0">
            {{$lang('save')}}
        </button>
    </div>
</template>

<script>
  import vueMixins from '../../vue-mixins';

  export default {
    name: "sidebar-preview",
    mixins: [vueMixins],
    data() {
      return {}
    },
    props: {
      preview: String
    },
    computed: {},
    methods: {
      oSave() {
        this.$emit('save')
      },
    },
  };
</script>

import InitializeApp from '../util/initialize-app';
import EditorApp from '../apps/editor/app';
import Vue from 'vue';
import copyText from '../components/copy-text';

export default {
  components: [
    copyText,
  ],

  init() {
    const appSelector = '[data-app=editor]';
    const props = {};
    InitializeApp(this.app, appSelector, props);

    this.components.forEach(c => {
      c.init();
    });
  },

  app(props) {
    return Vue.extend({
      render: h => h(EditorApp, { props })
    });
  },
};

import $ from 'jquery';
import validator from '../util/validator';
import deleteProfileModal from '../components/modal-delete-profile';

export default {
  init() {
    this.addEvents();
    deleteProfileModal.init();
  },

  addEvents() {
    $(document).on('click', '.js-edit-toggle', this.editToggle.bind(this));
    $(document).on('click', '.setting__save', this.settingSave.bind(this));
    $(document).on('click', '.setting__cancel', this.settingCancel.bind(this));
    $('form').on('blur', 'input', this.inputBlur.bind(this));
  },

  inputBlur({ currentTarget }) {
    const $target = $(currentTarget);
    let $form = $target.closest('.setting__edit');
    const errors = this.validate( $form );

    // If error in blured input, show error for that input
    if (errors.indexOf($target.attr('name')) !== -1) {
      this.showErrors([$target]);
    } else {
      this.showSuccess([$target]);
    }
  },

  showErrors(errors, message = false) {
    errors.forEach((error) => {
      let $input = error;
      let $wrapper = $input.parent();

      if (!$wrapper.hasClass('error')) {
        if ($wrapper.hasClass('checkbox-wrapper__label')) {
          $('<span class="error__message"><i class="fal fa-info-circle"></i> ' + (message ? message : $wrapper.data('required')) + '</span>').insertAfter($wrapper);
        } else {
          $('<span class="error__message"><i class="fal fa-info-circle"></i> ' + (message ? message : $wrapper.data('required')) + '</span>').insertAfter($input);
        }
        $wrapper.addClass('error').removeClass('success');
      }
    });

    $([document.documentElement, document.body]).animate({
      scrollTop: $('span.error__message').first().offset().top - 150
    }, 500);
  },

  showSuccess(successes) {
    successes.forEach((success) => {
      let $input = success;
      let $wrapper = $input.parent();

      if (!$wrapper.hasClass('success')) {
        $wrapper.addClass('success').removeClass('error');
      }

      if ($wrapper.hasClass('checkbox-wrapper__label')) {
        $wrapper.next('.error__message').remove();
      } else {
        $wrapper.find('.error__message').remove();
      }
    });
  },

  editToggle({ currentTarget }) {
    event.preventDefault();

    $(currentTarget).closest('.setting').addClass('active');

    $(currentTarget).closest('.setting').find('.setting__setting-details').slideUp(350, function() {
      $(currentTarget).closest('.setting').find('.setting__edit').slideToggle(450);
    });
  },

  settingSave({ currentTarget }) {
    event.preventDefault();
    let $target = $(currentTarget);
    let $form = $target.closest('.setting__edit');

    $target.addClass('btn--loading');

    if ( this.validate($form).length === 0 ) {
      let data = $form.serializeArray();

      this.postUserUpdate(data).always( (response, status) => {
        if (status === 'success') {
          this.updateUserInformation(response.user_data);
          $target.closest('.setting').removeClass('active');

          $target.closest('.setting').find('.setting__edit').slideUp(400, function() {
            $target.closest('.setting').find('.setting__setting-details').slideDown(400);
          });
        }

        $target.removeClass('btn--loading');
      });

    } else {
      $target.removeClass('btn--loading');
    }
  },

  settingCancel({ currentTarget }) {
    event.preventDefault();

    $(currentTarget).closest('.setting').removeClass('active');

    $(currentTarget).closest('.setting').find('.setting__edit').slideUp(400, function() {
      $(currentTarget).closest('.setting').find('.setting__setting-details').slideDown(400);
    });
  },

  updateUserInformation(data) {
    $('.js--name-field').html(data.first_name + ' ' + data.last_name);
    $('.js--user-email-field').html(data.user_email);
    $('.js--occupation-field').html(data.occupation);
    $('.js--location-field').html(data.location);
  },

  postUserUpdate(data) {
    return jQuery.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_private_update_user',
        form_data: data,
        nonce: window.ajax_object.nonce,
      },
      dataType: 'json',
      method: 'POST',
    });
  },

  validate($form) {
    let errors = [];

    switch ($form.attr('name')) {
      case 'name_form':
        if ($form.find('input[name="first_name"]').val().length === 0) {
          errors.push('first_name');
        }
        if ($form.find('input[name="last_name"]').val().length === 0) {
          errors.push('last_name');
        }
        break;
      case 'email_form':
        if (!validator.validateEmail($form.find('input[name="user_email"]').val())) {
          errors.push('user_email');
        }
        break;
      case 'password_form':
        if (!validator.validatePassword($form.find('input[name="user_password"]').val())) {
          errors.push('user_password');
        }
        break;
      case 'occupation_location_form':
        if ($form.find('input[name="location"]').val().length === 0) {
          errors.push('location');
        }
        break;
    }

    return errors;
  },

  deleteProfile() {
    $('.modal-delete-document-holder').modal();
  }
};

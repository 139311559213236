import $ from 'jquery';
import Modal from './modal';
import CreateDocument from './create-document';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('.guide-article__save-document').on('click', '.guide-article__heart', this.showSelectDocumentModal.bind(this));
    $(document).on('click', '.js-save-to-document', this.addToDocuments.bind(this));
    $(document).on('click', '.js-go-to-create-document', this.viewCreateDocument.bind(this));
    $(document).on('click', '.js-go-to-select-document', this.viewSelectDocument.bind(this));
    $(document).on('click', '.js--document-list a', this.selectDocument.bind(this));
    $(document).on('document-created', this.onDocumentCreated.bind(this));
  },

  showSelectDocumentModal(e) {
    this.$target = $(e.target).closest('.guide-article__block');

    this.flxID = this.$target.data('flx-id');
    this.sourceType = this.$target.data('source-type');
    this.postID = this.$target.data('post-id');
    this.modal = false;

    this.open();
    return false;
  },

  open() {
    const content = $('.modal-choose-document-holder').html();
    const opts = {
      classes: 'modal--choose-document',
    };

    this.modal = new Modal({
      ...opts,
      content,
    });

    this.modal.open();

    this.$modal = $('.site-backdrop').find('.modal--choose-document');
    this.$chooseContent = this.$modal.find('.modal__content--choose-document');
    this.$documentHolder = this.$chooseContent.find('.js--document-list');
    this.$chooseForm = this.$modal.find('#select-document-form');

    this.$chooseForm.find('input[name="post_id"]').val(this.postID);
    this.$chooseForm.find('input[name="source_type"]').val(this.sourceType);
    this.$chooseForm.find('input[name="type"]').val(this.sourceType);
    this.$chooseForm.find('input[name="flx_compontent"]').val(this.flxID);

    this.$errorWrapper = this.$chooseContent.find('.js--form-error');

    this.documentCreater = new CreateDocument();
    this.onDocumentCreated();
  },

  addToDocuments(e) {
    e.preventDefault();
    this.hideFormError();
    let data = this.$chooseForm.serializeArray();
    // add any documents from which the component should be removed
    data.push({'name': 'document_removed', 'value': this.$chooseForm.find('input[data-preselected="1"]:not(:checked)').map((t,e) => { return e.value}).get() });

    let $submitBtn = this.$chooseContent.find('.js-save-to-document');
    $submitBtn.addClass('btn--loading');
    const errors = this.validate();

    if (errors.length === 0) {
      this.saveToDocuments(data).always((response, textStatus) => {
        if (textStatus === 'success' && response.result === true) {
          // Set heart as selected/deselected
          if (response.posts.length === 0) {
            this.$target.removeClass('guide-article__block--saved');
          } else {
            this.$target.addClass('guide-article__block--saved');
          }

          // Set link by heart if only one document was choosen.
          if (response.posts.length === 1) {
            this.$target.find('.guide-article__save-document-text a').removeClass('invisible').attr('href', response.posts[0].permaLink);
          } else {
            this.$target.find('.guide-article__save-document-text a').addClass('invisible');
          }

          this.modal.close();

        } else if (textStatus === 'success') {
          this.showFormError( response.message );
        } else {
          this.showFormError( this.$errorWrapper.data('generic') );
        }
        $submitBtn.removeClass('btn--loading');
      });
    } else {
      this.showFormError( this.$documentHolder.data('required') );
      $submitBtn.removeClass('btn--loading');
    }
  },

  validate() {
    let errors = [];

    if (this.$chooseForm.find('input[name="document_select"]:checked').length === 0 && this.$chooseForm.find('input[data-preselected="1"]:not(:checked)').length === 0) {
      errors.push('document_select');
    }
    return errors;
  },

  saveToDocuments(data) {
    return $.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_private_save_to_documents',
        nonce: window.ajax_object.nonce,
        form_data: data,
      },
      dataType: 'json',
      method: 'POST',
    });
  },

  onDocumentCreated(e, post_id) {
    this.$documentHolder.css('opacity', 0.5);

    this.loadDocuments().always((response, textStatus) => {
      if (textStatus === 'success') {
        this.$documentHolder.html(response.content);
        $('input[value="' + post_id + '"]').prop('checked', true)
      }

      this.$documentHolder.css('opacity', 1);
    });
  },

  loadDocuments() {
    return $.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_private_get_my_documents',
        postID: window.ajax_object.post_id,
        nonce: window.ajax_object.nonce,
        flx_compontent: this.flxID,
      },
      dataType: 'json',
      method: 'POST',
    });
  },

  selectDocument(e) {
    e.preventDefault();
    let $target = e.target.tagName === 'A' ? $(e.target) : $(e.target) .closest('a');
    let $checkbox = $target.find('input[type="checkbox"]');
    $checkbox.prop('checked', !$checkbox.is(':checked'));
  },

  viewCreateDocument() {
    $('.modal__content--choose-document').fadeOut(300, function() {
      setTimeout(function() {
        $('.modal__content--create-document').fadeIn(300);
      },300);
    });
  },

  viewSelectDocument() {
    $('.modal__content--create-document').fadeOut(300, function() {
      setTimeout(function() {
        $('.modal__content--choose-document').fadeIn(300);
      },300);
    });
  },

  showFormError(message) {
    this.$errorWrapper.find('p').html( message );
    this.$errorWrapper.show();
  },

  hideFormError() {
    this.$errorWrapper.find('p').html('');
    this.$errorWrapper.hide();
  }
};


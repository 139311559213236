import $ from 'jquery';
import debounce from "../util/debounce";

export default class CreateDocument {
  constructor() {
    this.$createDocumentForm = $('.site-backdrop').find('#create-document-form');
    this.$errorWrapper = this.$createDocumentForm.find('.js--form-error');
    this.addEvents();
  }

  addEvents() {
    this.$createDocumentForm.on('click', '.js-create-document', this.onSubmit.bind(this));
    this.$createDocumentForm.on('input', 'input', debounce(this.inputChange, 300));
    this.$createDocumentForm.on('blur', 'input', this.inputBlur.bind(this));
  }

  onSubmit() {
    this.hideErrors();
    this.hideFormError();
    let $submitBtn = this.$createDocumentForm.find('.js-create-document');
    $submitBtn.addClass('btn--loading');
    const data = this.$createDocumentForm.serializeArray();
    const errors = this.validate();

    if ( errors.length === 0 ) {
      this.createDocument(data).always((response, textStatus) => {
        $submitBtn.removeClass('btn--loading');

        if (textStatus === 'success' && response.success === true) {
          $(document).trigger('document-created', [response.postID]);
          this.$createDocumentForm.find('.js-go-to-select-document').trigger('click');
          this.$createDocumentForm[0].reset();
        } else if (textStatus === 'success') {
          // Input error
          this.showErrors( [response.field], response.message[0] );
        } else if (textStatus === 'error') {
          // Generic error
          this.showFormError( this.$errorWrapper.data('generic') );
        }
      });

    } else {
      this.showErrors(errors);
      $submitBtn.removeClass('btn--loading');
    }
  }

  createDocument(data) {
    return $.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_private_create_document',
        form_data: data,
        nonce: window.ajax_object.nonce,
      },
      dataType: 'json',
      method: 'POST',
    });
  }

  inputChange () {
    let $target = $(this);
    let $wrapper = $target.parent();
    $wrapper.removeClass('error','success');
    $wrapper.find('.error__message').remove();
  };

  inputBlur(e) {
    const $target = $(e.target);
    const errors = this.validate();

    // If error in blured input, show error for that input
    if (errors.indexOf($target.attr('name')) !== -1) {
      this.showErrors([$target.attr('name')]);
    } else {
      this.showSuccess([$target.attr('name')]);
    }
  }

  validate() {
    let errors = [];
    const titleLength = this.$createDocumentForm.find('input[name="document_name"]').val().length;

    if (titleLength < 10 || titleLength > 300) {
      errors.push('document_name');
    }
    return errors;
  }

  hideErrors() {
    this.$createDocumentForm.find('.error').removeClass('error');
    this.$createDocumentForm.find('.error__message').remove();
  }

  showErrors(errors, message = false) {
    errors.forEach((error) => {
      let $input = this.$createDocumentForm.find("[name='" + error + "']");
      let $wrapper = $input.parent();

      if (!$wrapper.hasClass('error')) {
        $('<span class="error__message"><i class="fal fa-info-circle"></i> ' + (message ? message : $wrapper.data('required')) + '</span>').insertAfter($input);
        $wrapper.addClass('error').removeClass('success');
      }
    });
  }

  showSuccess(successes) {
    successes.forEach((success) => {
      let $input = this.$createDocumentForm.find("[name='" + success + "']");
      let $wrapper = $input.parent();

      if (!$wrapper.hasClass('success')) {
        $wrapper.addClass('success').removeClass('error');
      }
      $wrapper.find('.error__message').remove();
    });
  }

  showFormError(message) {
    this.$errorWrapper.find('p').html( message );
    this.$errorWrapper.show();
  }

  hideFormError() {
    this.$errorWrapper.find('p').html('');
    this.$errorWrapper.hide();
  }
}

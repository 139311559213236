import $ from 'jquery';

let selectedTextString = '';
let selectedRemoveTextString = '';
let $highlightTooltip;
let $highlightTooltipRemove;
let $currentPTag;
let $currentMarkTag;
const self = this;
const MIN_WORD_COUNT = 5;

export default {

  init() {
    if (!window.getSelection || typeof AG_HIGHLIGHT === 'undefined') {
      return;
    }
    AG_HIGHLIGHT.STORED_TEXTS = AG_HIGHLIGHT.STORED_TEXTS || [];
    this.setVars();
    this.modifyDOM();
    this.addEvents();
  },

  setVars() {
    $highlightTooltip = $('<div class="guide-highlight"></div>');
    $highlightTooltipRemove = $('<div class="guide-highlight guide-highlight--remove"></div>');
    $highlightTooltip.appendTo('body');
    $highlightTooltipRemove.appendTo('body');
  },

  modifyDOM() {
    $('.guide-article p').each(function () {
      $(this).html($(this).html().replace(/&nbsp;/gi, ' '));
    });
    this.insertMarkTags();
  },

  addEvents() {
    $('body').on('mouseup', this.getSelectedParagraphText);
    $('body').on('click', '.guide-article p mark', this.showTooltipForRemoval);
    $(document).on('click', this.hideTooltipForRemoval);
    $highlightTooltip.on('click', this.saveSelectedText);
    $highlightTooltipRemove.on('click', this.removeSelectedText);
  },

  saveSelectedText() {
    if (!selectedTextString.length) {
      $highlightTooltip.hide();
      return;
    }
    AG_HIGHLIGHT.STORED_TEXTS.push(selectedTextString);
    $highlightTooltip.hide();
    self.insertMarkTags();
    self.saveTextsToAPI();
  },

  showTooltipForRemoval(e) {
    e.stopPropagation();
    const rect = $(this)[0].getBoundingClientRect();
    selectedRemoveTextString = $(this).html();
    $currentMarkTag = $(this);
    self.positionTooltip($highlightTooltipRemove, rect);
  },

  hideTooltipForRemoval(e) {
    if ($highlightTooltipRemove.is(':visible') && !$(e.currentTarget).hasClass('guide-highlight--remove')) {
      $highlightTooltipRemove.hide();
    }
  },

  removeSelectedText() {
    if (!selectedRemoveTextString.length) {
      $highlightTooltipRemove.hide();
      return;
    }
    const index = AG_HIGHLIGHT.STORED_TEXTS.indexOf(selectedRemoveTextString);
    AG_HIGHLIGHT.STORED_TEXTS.splice(index, 1);
    const $parentPTag = $currentMarkTag.closest('p');
    $currentMarkTag.replaceWith(selectedRemoveTextString);
    if (!$parentPTag.find('mark').length) {
      $parentPTag.removeClass('has-mark');
    }
    $highlightTooltipRemove.hide();
    self.saveTextsToAPI();
  },

  getSelectedParagraphText(e) {
    setTimeout(() => {
      if ($(e.target).closest('mark').length) {
        return;
      }
      const selection = window.getSelection();
      if (!$(selection.focusNode).closest('p').closest('.guide-article').length) {
        return;
      }
      const isSameNodeSelected = self.checkIfSameNodeIsSelected(selection);
      if (!isSameNodeSelected) {
        return;
      }
      const wordCount = selection.toString().split(' ').length;
      if (wordCount < MIN_WORD_COUNT) {
        $highlightTooltip.hide();
        return;
      }
      selectedTextString = self.getHtmlFromSelection(selection);
      $currentPTag = selection.focusNode.nodeName === 'P' ? $(selection.focusNode) : $(selection.focusNode).closest('p');
      self.showTooltip();
    }, 100);
  },

  checkIfSameNodeIsSelected(selection) {
    if (selection.anchorNode.nodeName === '#text' && selection.anchorNode.parentNode.childNodes.length === 1) {
      return true;
    }
    const $focusParentPElement = selection.focusNode.nodeName === 'P' ? $(selection.focusNode) : $(selection.focusNode).closest('p');
    const $anchorParentPElement = selection.anchorNode.nodeName === 'P' ? $(selection.anchorNode) : $(selection.anchorNode).closest('p');
    if ($focusParentPElement[0] !== $anchorParentPElement[0]) {
      return false;
    }
    return true;
  },

  getHtmlFromSelection(selection) {
    let selectionString = selection.toString();
    selection.anchorNode.parentElement.childNodes.forEach((childNode) => {
      if (childNode.nodeName !== '#text' && childNode.nodeName !== 'BR' && selection.containsNode(childNode)) {
        const lowerCasedNodeName = childNode.nodeName.toLowerCase();
        selectionString = selectionString.replace(childNode.innerText, `<${lowerCasedNodeName}>${childNode.innerText}</${lowerCasedNodeName}>`);
      }
    });
    selectionString = selectionString.trim().replace(/<\/br>/gi, "").replace(/<br>/gi, "");
    return selectionString;
  },

  saveTextsToAPI() {
    $.post('/Api/AgUserTexts/Save',
      { texts: AG_HIGHLIGHT.STORED_TEXTS, pageId: AG_HIGHLIGHT.CURRENT_PAGE_ID },
      (res) => { console.log(res); });
  },

  insertMarkTags() {
    $('.guide-article p').each(function () {
      let isReplaced = false;
      let $elemHtml = $(this).html().replace(/&nbsp;/gi, ' ');

      AG_HIGHLIGHT.STORED_TEXTS.forEach((text) => {
        if ($elemHtml.indexOf(text) !== -1 && $elemHtml.indexOf(`<mark>${text}</mark>`) === -1) {
          $elemHtml = $elemHtml.replace(text, `<mark>${text}</mark>`);
          isReplaced = true;
        }
      });

      if (isReplaced) {
        $(this).html($elemHtml);
        $(this).addClass('has-mark');
      }
    });
  },

  positionTooltip($tooltipElem, rect) {
    $tooltipElem.css({
      display: 'block',
      top: `${(rect.top - 50) + $(window).scrollTop()}px`,
      left: `${(rect.left + (rect.width / 2))}px`,
    });
  },

  showTooltip() {
    const rect = window.getSelection().getRangeAt(0).getBoundingClientRect();
    self.positionTooltip($highlightTooltip, rect);
  },

};

import $ from 'jquery';
import searchFilter from '../components/search-results-filter';

export default {
  components: [
    searchFilter,
  ],

  init() {
    this.$loader = $('.js--document-loader');
    this.$wrapper = $('.reports');

    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
    $(document).on('change', 'input[name="target_group"]', this.eventHandlers.search.bind(this));
    $(document).on('change', 'input[name="area"]', this.eventHandlers.search.bind(this));
    $(document).on('click', '.js--document-reset', this.eventHandlers.reset.bind(this));
    $(document).on('click', '.js--all-documents', this.eventHandlers.getAll.bind(this));
  },

  modifyDOM() {
  },

  eventHandlers: {
    reset() {
      $('input[name="area"]').prop('checked', false);
      $('input[name="target_group"]').prop('checked', false);
      $('.checkbox-wrapper__label').removeClass('active');

      this.eventHandlers.search.bind(this)();
    },
    getAll(e) {
      let $target = $(e.target);
      let targets = $('input[name="target_group"]:checked').val();
      let areas = $('input[name="area"]:checked').val();
      let offset = $target.data('more-limit');

      this.$loader.removeClass('hidden');

      this.eventHandlers.doSearch({targets, areas, offset}).always( (response, textStatus) => {
        if (textStatus === 'success') {
          $target.remove();
          this.$wrapper.append(response.content);
        }
        this.$loader.addClass('hidden');
      });

    },
    search() {
      let targets = $('input[name="target_group"]:checked').map(function(){return $(this).val()}).get();
      let areas = $('input[name="area"]:checked').map(function(){return $(this).val()}).get()

      this.$loader.removeClass('hidden');

      this.eventHandlers.doSearch({targets, areas, post_count: 7}).always( (response, textStatus) => {
        if (textStatus === 'success') {
          this.$wrapper.html(response.content);
        }
        this.$loader.addClass('hidden');
      });
    },
    doSearch(data) {
      data['action'] = 'mk_get_documents';
      data['nonce'] = window.ajax_object.nonce;

      return $.ajax({
        url: window.ajax_object.ajax_url,
        data,
        dataType: 'json',
        method: 'POST',
      });
    }
  }
};

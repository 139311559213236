import navigation from '../components/my-pages-navigation';
import onboarding from '../components/modal-onboarding';
import bookmarks from '../components/my-pages-bookmarks';
import accountSettings from '../components/account-settings';

export default {
  components: [
    navigation,
    onboarding,
    bookmarks,
    accountSettings,
  ],

  init() {
    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
  },

  modifyDOM() {
  },
};

import $ from 'jquery';
import searchFilter from '../components/search-results-filter';


export default {
  components: [
    searchFilter,
  ],

  init() {
    this.$loader = $('.js--search-loader');
    this.$wrapper = $('#search-result-wrapper');
    this.$loadmoreBtn = $('.js--global-search-more');

    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
    $(document).on('change', 'input[name="type"]', this.eventHandlers.search.bind(this, 1));
    $(document).on('change', 'input[name="area"]', this.eventHandlers.search.bind(this, 1));
    $(document).on('click', '.js--clear-search', this.eventHandlers.reset.bind(this));
    this.$loadmoreBtn.on('click', this.eventHandlers.getMore.bind(this));
  },

  modifyDOM() {
  },

  eventHandlers: {
    reset() {
      $('input[name="area"]').prop('checked', false);
      $('input[name="target_group"]').prop('checked', false);
      $('.checkbox-wrapper__label').removeClass('active');

      this.eventHandlers.search.bind(this, 1)();
    },
    getMore(e) {
      this.eventHandlers.search.bind(this, $(e.target).data('page'))();
    },
    search(page = 1) {
      let types = $('input[name="type"]:checked').map(function(){return $(this).val()}).get();
      let areas = $('input[name="area"]:checked').map(function(){return $(this).val()}).get();
      let search = $('#s').val();
      this.$loader.removeClass('hidden');

      this.eventHandlers.doSearch({search, types, areas, page}).always((response, textStatus) => {
        if (textStatus === 'success') {
          page === 1 ? this.$wrapper.html(response.content) : this.$wrapper.append(response.content);

          if (response.has_more === true) {
            this.$loadmoreBtn.show();

            if (page !== 1) {
              this.$loadmoreBtn.data('page', page + 1);
            }

          } else {
            this.$loadmoreBtn.hide();
          }
        }
        this.$loader.addClass('hidden');
      });
    },
    doSearch(data) {
      data['action'] = 'mk_default_search';
      data['nonce'] = window.ajax_object.nonce;

      return $.ajax({
        url: window.ajax_object.ajax_url,
        data,
        dataType: 'json',
        method: 'POST',
      });
    }
  },
};

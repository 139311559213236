import $ from 'jquery';

export default {
  init() {
    this.addEvents();
    this.modifyDOM();
    this.searchResultsNavigation();
  },

  addEvents() {
    $(document).on('click', '.radio-wrapper__input', this.toggleRadioLabel.bind(this));
    $(document).on('click', '.checkbox-wrapper__input', this.toggleCheckboxLabel.bind(this));
    $(document).on('click', '.custom-select', this.customSelect.bind(this));
    $(document).on('keyup', '.search-field__input', this.searchField.bind(this));
    $(document).on('click', '.js-clear-search', this.clearSearch.bind(this));
    $(document).on('keyup', '.search-input', this.showClearSearch.bind(this));
  },

  modifyDOM() {
    $(document).click(function(event) {
      if (!$(event.target).hasClass('custom-select__toggle')) {
        $('.custom-select').removeClass('active');
      }
    });

    $(document).click(function(event) {
      if (!$(event.target).hasClass('search-field__input')) {
        $('.search-field').removeClass('active');
      }
    });
  },

  searchResultsNavigation() {
    var li = $('.search-field__results li');
    var liSelected;
    $(li).keydown(function(e) {

      /* Key down */
      if (e.which === 40) {
          if (liSelected) {
            var next = liSelected.next();
            liSelected.removeClass('selected');

            if (next.length > 0) {
                liSelected = next.addClass('selected');
            } else {
                liSelected = li.eq(0).addClass('selected');
            }
          } else {
              liSelected = li.eq(0).addClass('selected');
          }
      }
      /* Key up */
      else if (e.which === 38) {
          if (liSelected) {

              liSelected.removeClass('selected');
              next = liSelected.prev();

              if (next.length > 0) {
                  liSelected = next.addClass('selected');
              } else {
                  liSelected = li.last().addClass('selected');
              }
          } else {
              liSelected = li.last().addClass('selected');
          }
      }
      /* Enter, go to href */
      else if (e.which === 13) {

        var liSelectedLink = liSelected.find('a');
        window.location.href = liSelectedLink.attr('href');

        $('.search-field').removeClass('active');
        $('.search-field__input:focus').blur();
      }
    });
  },

  toggleRadioLabel() {
    $('label:has(input:radio:checked)').addClass('active');
    $('label:has(input:radio:not(:checked))').removeClass('active');
  },

  toggleCheckboxLabel() {
    $('label:has(input:checkbox:checked)').addClass('active');
    $('label:has(input:checkbox:not(:checked))').removeClass('active');
  },

  searchField() {
    if ($('.search-field__input').val().length > 0) {
      $('.search-field').addClass('active');
    } else {
      $('.search-field').removeClass('active');
    }
  },

  clearSearch() {
    $('.search-input').val('');
    $('.js-clear-search').addClass('hidden');
  },

  showClearSearch() {
    if ($('.search-input').val().length > 0) {
      $('.js-clear-search').removeClass('hidden');
    } else {
      $('.js-clear-search').addClass('hidden');
    }
  },

  customSelect({ currentTarget }) {
    $(currentTarget).toggleClass('active');

    for (const option of $('.custom-select__item')) {
      option.addEventListener('click', function() {
        if (!this.hasAttribute('data-selected')) {

          if(this.parentNode.querySelector('.custom-select__item[data-selected]')) {
            this.parentNode.querySelector('.custom-select__item[data-selected]').removeAttribute('data-selected');
          }

          this.setAttribute('data-selected', true);
          this.closest('.custom-select').querySelector('.custom-select__value').textContent = this.textContent;
        }
      })
  }

  },
};

import scrollToElement from '../components/scroll-to-element';

export default {
  components: [
    scrollToElement,
  ],

  init() {
    this.addEvents();
    this.modifyDOM();

    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
  },

  modifyDOM() {
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document-block document-block--editable",class:{'border-red' : _vm.componentError}},[_c('reorder',{attrs:{"position":_vm.position}}),_vm._v(" "),_c('remove',{attrs:{"position":_vm.position}}),_vm._v(" "),_c('blockToolbar',{attrs:{"view":_vm.view},on:{"view-change":_vm.onViewChange}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.inPreview),expression:"inPreview"}],staticClass:"document-block__content"},[_c('h2',{staticClass:"section-heading"},[_vm._v(_vm._s(_vm.component.title))]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.component.content)}})]),_vm._v(" "),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.inEdit),expression:"inEdit"}]},[_c('error',{attrs:{"has-error":_vm.hasError,"message":_vm.errorMessage}}),_vm._v(" "),_c('label',{attrs:{"for":"title"}},[_vm._v(_vm._s(_vm.$lang('textTitle')))]),_vm._v(" "),_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.component.title),expression:"component.title"}],staticClass:"mb-16 pr-80",attrs:{"type":"text","id":"title","name":"title"},domProps:{"value":(_vm.component.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.component, "title", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"absolute right-8 top-16 text-gray-border"},[_vm._v(_vm._s(_vm.titleLength)+" / 200")])]),_vm._v(" "),_c('div',{staticClass:"tiny mb-12 md:mb-24"},[_c('tiny-editor',{attrs:{"api-key":"no-api-key","id":'tiny-content' + _vm.position,"init":{
                height: 300,
                resize: false,
                menubar: false,
                content_css: _vm.contentCSS,
                plugins: ['lists'],
                toolbar: 'undo redo | ' +
                          'bold italic | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist | ' +
                          'removeformat',
            }},model:{value:(_vm.component.content),callback:function ($$v) {_vm.$set(_vm.component, "content", $$v)},expression:"component.content"}})],1),_vm._v(" "),_c('button',{staticClass:"btn sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0",attrs:{"type":"button"},on:{"click":function($event){return _vm.onSave('preview')}}},[_vm._v(_vm._s(_vm.$lang('add')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
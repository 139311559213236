import $ from 'jquery';
import nav from '../components/nav';
import swiper from '../components/swiper';
import embeddedVideo from '../components/embedded-video';
import modalLogin from '../components/modal-login';
import forms from '../components/forms';
import openSearch from '../components/open-search';

export default {
  components: [
    nav,
    swiper,
    embeddedVideo,
    modalLogin,
    forms,
    openSearch,
  ],

  init() {
    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });

    $('form input[name="s"]').on('keypress', function(e) {
      return e.which !== 13;
    });
  },

  addEvents() {
  },

  modifyDOM() {
  },
};

<template>
    <button class="absolute top-16 right-24 sm:right-16 z-10" type="button" @click="remove">
        <i class="fal fa-times text-2xl sm:text-lg text-gray-text-light"></i>
    </button>
</template>

<script>
  import { store } from '../store/store';

  export default {
    name: "remove",
    data() {
      return {
      }
    },
    props: {
      position: Number,
    },
    methods: {
      remove() {
        store.mutations.removeContent(this.position);
      },
    },
  };
</script>

import $ from 'jquery';
import embeddedVideo from '../components/embedded-video';
import bookmark from '../components/bookmark';

export default {
  components: [
    embeddedVideo,
    bookmark,
  ],

  init() {
    this.$container = $('#video-container');
    this.view = this.$container.data('view');
    this.filter = this.$container.data('filter');

    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
    $('.js--load-all-videos').on('click', this.getPosts.bind(this))
  },

  modifyDOM() {
  },

  getPosts(e) {
    let $btn = $(e.target);
    $btn.addClass('btn--loading');

    this.loadPosts().then((result) => {
      this.$container.append(result.markup);
      $btn.removeClass('btn--loading').delay(500).hide();
    });
  },

  loadPosts() {
    return $.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_get_post_items',
        nonce: window.ajax_object.nonce,
        filter: this.filter,
        view: this.view,
        post_type: 'video'
      },
      dataType: 'json',
      method: 'POST',
    });
  }
};

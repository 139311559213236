import guideHighlight from '../components/guide-highlight';
import modalImage from '../components/modal-image';
import expandableCard from '../components/expandable-card';
import chooseDocument from '../components/modal-choose-document';
import bookmark from '../components/bookmark';

export default {
  components: [
    guideHighlight,
    modalImage,
    expandableCard,
    chooseDocument,
    bookmark,
  ],

  init() {
    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
  },

  modifyDOM() {
  },
};

import $ from 'jquery';
import embeddedVideo from '../components/embedded-video';
import bookmark from '../components/bookmark';
import chooseDocument from '../components/modal-choose-document';

export default {
  components: [
    embeddedVideo,
    bookmark,
    chooseDocument,
  ],

  init() {
    this.addEvents();
    this.modifyDOM();

    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
  },

  modifyDOM() {
  },
};

import $ from 'jquery';

export default class Modal {
  constructor(opts = {}) {
    const defaultOpts = {
      content: '',
      classes: '',
      closeOnBackdropClick: true,
      closeOnModalClick: false,
      displayClose: true,
      onClose: () => { },
    };

    this.opts = Object.assign({}, defaultOpts, opts);
    this.$body = $('body');
    this.$modal = $(`
      <div class="modal ${this.opts.classes}">
        <div class="modal__container">${this.opts.content} </div>
      </div>`);
    this.$backdrop = $('.site-backdrop');

    if (this.opts.closeOnBackdropClick) {
      this.$backdrop.on('click', this.close.bind(this));
    }

    if (this.opts.closeOnModalClick) {
      this.$modal.on('click', this.close.bind(this));
    }

    this.$modal.on('click', '.js--close-modal', this.close.bind(this));

    if (this.opts.displayClose) {
      this.$close = $('<button class="modal__close close"></button>');
      this.$close.on('click', this.close.bind(this));
    }
  }

  setContent(content) {
    content = content instanceof $ ? content : $(content);
    this.$modal.find('.modal__content').empty().append(content);
    content.show();
  }

  open() {

    if (this.opts.displayClose) {
      this.$modal.find('.modal__container').append(this.$close);
    }

    if (this.opts.backdropClasses) {
      this.$backdrop.addClass(this.opts.backdropClasses);
    }

    this.$body.addClass('has-modal');
    this.$backdrop.addClass('site-backdrop--active');
    this.$backdrop.append(this.$modal);
    if (this.opts.content instanceof $) {
      this.$modal.find('.modal__content').empty().append(this.opts.content);
      this.opts.content.show();
    }
  }

  close(event) {
    if (typeof event === 'undefined' || $(event.target).hasClass('site-backdrop') || $(event.target).hasClass('modal__close') || $(event.target).hasClass('js--close-modal')) {
      this.opts.onClose();
      this.$body.removeClass('has-modal');
      this.$modal.remove();
      this.$backdrop.removeClass('site-backdrop--active');

      if (this.opts.backdropClasses) {
        this.$backdrop.removeClass(this.opts.backdropClasses);
      }
    }
  }
}

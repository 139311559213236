<template>
    <div class="document-block document-block--editable" v-bind:class="{'border-red' : hasError}">

        <blockToolbar :view="view" @view-change="onViewChange"/>

        <div class="document-block__content">
            <section class="pt-12 sm:pt-0">
                <div class="expandable-card w-full rounded-lg bg-blue">
                    <a href="javascript:void(0)" class="expandable-card__toggle p-24 text-xl w-full block text-gray-dark items-center flex justify-between">
                        <h4 class="mb-0 text-xl">{{$lang('sources')}}</h4>
                    </a>
                    <div class="expandable-card__content hidden px-24 pb-24 print:block" style="display: block;">

                        <slot v-for="(source, index) in component">
                            <component :is="source.type" :source="source" :view="view" :key="index" :position="parseInt(index, 10)" @view-change="onViewChange" :has-error="sourcesWithError.indexOf(source.sourceID) !== -1 && status.isValidating"/>
                        </slot>

                        <button v-if="inEdit" type="button" class="btn sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0" @click="addBookSource">{{$lang('addBookSource')}}</button>
                        <button v-if="inEdit" type="button" class="btn sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0" @click="addInternetSource">{{$lang('addInternetSource')}}</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
  import {store} from '../store/store';
  import vueMixins from '../../vue-mixins';
  import reorder from '../parts/part-reorder';
  import remove from '../parts/part-remove';
  import blockToolbar from '../parts/part-block-toolbar';
  import bookSource from '../parts/part-book-source';
  import internetSource from '../parts/part-internet-source';

  export default {
    name: "Source",
    mixins: [vueMixins],
    data() {
      return {
        loading: false,
        view: 'edit',
        postID: store.state.document.id,
        status: store.state.status,
      }
    },
    props: {
      component: Array,
      sourcesWithError: Array,
    },
    components: {
      reorder,
      remove,
      blockToolbar,
      'book': bookSource,
      'internet': internetSource,
    },
    computed: {
      hasError() {
        return this.sourcesWithError.length > 0 && this.status.isValidating;
      },
      hasSources() {
        return this.component.length > 0;
      },
      inPreview: function() {
        return this.view === 'preview';
      },
      inEdit: function() {
        return this.view === 'edit';
      },
    },
    watch: {
    },
    methods: {
      onViewChange(view) {
        this.view = view;
      },
      addBookSource() {
        store.mutations.addBookSource();
      },
      addInternetSource() {
        store.mutations.addInternetSource();
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        if (this.hasSources) {
          this.onViewChange('preview');
        }
      });
    }
  };
</script>

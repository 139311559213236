import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.my-pages-navigation__toggle', this.myPagesNavToggle.bind(this));
  },

  myPagesNavToggle({ currentTarget }) {
    $(currentTarget).toggleClass('my-pages-navigation__toggle--active');
    $(currentTarget).next('.my-pages-navigation__content').slideToggle();
  },
};

<template>
    <div class="document-block">
        <reorder :position="position" />
        <remove :position="position" />

        <a :href="component.source" target="_blank" class="h-40 w-40 rounded-full border-gray-border-light bg-white flex justify-center items-center text-gray-border border absolute bottom-0 left-32 transform translate-y-1/2">
            <i class="fa fa-link"></i>
        </a>

        <div class="document-block__content" v-html="component.markup"></div>
    </div>
</template>

<script>
  import vueMixins from '../../vue-mixins';
  import reorder from '../parts/part-reorder';
  import remove from '../parts/part-remove';

  export default {
    name: "handbook",
    mixins: [vueMixins],
    data() {
      return {
      }
    },
    props: {
      component: Object,
      position: Number,
    },
    components: {
      reorder,
      remove,
    },
    computed: {
    },
    methods: {
    },
    beforeMount: function () {
    }
  };
</script>

import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('.embedded-video__placeholder').on('click', this.playVideo);
  },

  playVideo(e) {
    let target = $(e.target);
    let video = false;

    if (!target.hasClass('embedded-video__placeholder')) {
      target = target.closest('.embedded-video__placeholder');
    }

    if (target.data('video').indexOf('vimeo.com')) {
      video = `<div class="embedded-video__container relative w-full"><iframe src="${ target.data('video') }?autoplay=1&title=0&byline=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script></div>`;
    } else {
      video = `<div class="embedded-video__container relative w-full"><iframe width="560" height="315" src="${ target.data('video') }?autoplay=1" allowfullscreen allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe></div>`;
    }

    $(this).replaceWith(video);
  },
};

import Modal from '../components/modal';
import $ from 'jquery';
import Cookies from 'js-cookie';
import Swiper from 'swiper';

const COOKIE_NAME = 'modal-onboarding';

const classes = {
  active: 'site-backdrop--active',
};

export default {

  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $(document).on('click', '.js-onboarding-modal', this.showOnboardingModal.bind(this));
  },

  modifyDOM() {
    if (Cookies.get(COOKIE_NAME) !== "true") {
        this.open();
    }
  },

  showOnboardingModal() {
    this.open();
    return false;
  },

  open() {
    const content = $('.modal-onboarding-holder').html();
    const opts = {
      classes: 'modal--onboarding',
      onClose: () => {
          Cookies.set(COOKIE_NAME, true, 365);
      }
    };

    const modal = new Modal({
      ...opts,
      content,
    });

    modal.open();

    new Swiper('.swiper-onboarding', {
      navigation: {
          nextEl: '.swiper-next',
      },
      pagination: {
          el: '.swiper-pagination',
          clickable: true,
      },
    });
  },
};

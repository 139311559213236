import Swiper from 'swiper';

export default {
  init() {
    this.addEvents();
    this.modifyDom();
  },

  modifyDom() {
    this.applySwiper();
  },

  addEvents() {
  },

  applySwiper() {
      new Swiper('.swiper-default', {
        loop: true,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      });

      new Swiper('.swiper-desktop', {
        loop: false,
        slidesPerView: 'auto',
        noSwiping: true,
        navigation: {
          nextEl: '.swiper-next-desktop',
          prevEl: '.swiper-prev-desktop',
        },
      });

      new Swiper('.swiper-mobile', {
        loop: false,
        slidesPerView: 'auto',
        noSwiping: true,
        navigation: {
          nextEl: '.swiper-next-mobile',
          prevEl: '.swiper-prev-mobile',
        },
        breakpoints: {
          /*1024: {
            simulateTouch: false,
            allowTouchMove: false
          }*/
        }
      });
  }
};


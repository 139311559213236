<template>
    <div class="relative add-block z-30" v-bind:class="{ 'z-50': show  }">
        <button @click="onToggleTools" class="h-40 w-40 rounded-full bg-blue_dark flex justify-center items-center text-gray-dark mx-auto cursor-pointer mb-40 sm:mb-24 relative z-20">
            <i class="fa fa-plus pointer-events-none"></i>
        </button>

        <div v-show="show" class="add-block__choose-block rounded bg-white border-blue_dark border shadow pt-32 p-32 pb-0 md:max-w-full w-11/12 sm:w-300 mx-auto absolute top-20 transform-x-50">
            <tools :position="position" @add-block="onAddBlock" />
        </div>
    </div>
</template>

<script>
  import vueMixins from '../../vue-mixins';
  import tools from '../parts/part-tools'

  export default {
    name: "add-block",
    mixins: [vueMixins],
    data() {
      return {
        show: false,
      }
    },
    props: {
      position: Number,
    },
    components: {
      tools,
    },
    computed: {
    },
    methods: {
      onToggleTools() {
        this.show = !this.show;
      },
      onAddBlock(tool) {
        this.onToggleTools();
        this.$emit('add-block', tool);
      }
    },
    beforeMount: function () {
    }
  };
</script>

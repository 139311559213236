<template>
    <div class="absolute top-0 right-0 bottom-0 left-0 bg-white bg-opacity-75 flex justify-center items-start" v-show="isLoading">
        <i class="far fa-circle-notch fa-spin fa-10x text-gray-border m-64"></i>
    </div>
</template>

<script>
  export default {
    name: "componentLoader",
    props: {
      isLoading: Boolean,
    },
    data() {
      return {
      }
    },
  };
</script>

import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '#scroll', this.scrollToElement.bind(this));
  },

  scrollToElement(event) {
    if ($('#scroll-element').length) {
      $('html, body').animate({
        scrollTop: $('#scroll-element').offset().top-90
      }, 1000);
    }
  },
};

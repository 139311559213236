import $ from 'jquery';

export default {
  init() {
    this.$menu = $('.navigation-widget');
    this.$article = $('.guide-article');
    this.originalArticleHeight = this.$article.height();
    this.addEvents();
    this.navWidgetHeightAdjustment(0);
  },

  addEvents() {
    $(document).on('click', '.navigation-widget__toggle', this.navWidgetToggle.bind(this));
    $(document).on('click', '.navigation-widget__list-item--has-children .navigation-widget__item', this.navWidgetToggle.bind(this));
    $(document).on('click', '.navigation-widget__main-title', this.navWidgetMainToggle.bind(this));
    $(document).on('click', '.navigation-widget__header', this.navWidgetMobileToggle.bind(this));
  },

  navWidgetToggle({ currentTarget }) {
    let $target = $(currentTarget).hasClass('navigation-widget__item') ? $(currentTarget).next() : $(currentTarget);
    $target.siblings('ul').slideToggle(150, this.navWidgetHeightAdjustment.bind(this));
    $target.toggleClass('navigation-widget__toggle--up navigation-widget__toggle--down');
    $target.closest('.navigation-widget__list-item').toggleClass('navigation-widget__list-item--open');

    if ($target.parent('.navigation-widget__main-item').length) {
      $target.parent('.navigation-widget__main-item').toggleClass('navigation-widget__main-item--collapsed');
    }

    return false;
  },

  navWidgetMainToggle({ currentTarget }) {
    $(currentTarget).parent('.navigation-widget__main-item').toggleClass('navigation-widget__main-item--collapsed');
    $(currentTarget).siblings('.navigation-widget__toggle').toggleClass('navigation-widget__toggle--up navigation-widget__toggle--down');
    $(currentTarget).siblings('ul').slideToggle(150, this.navWidgetHeightAdjustment.bind(this));
    return false;
  },

  navWidgetMobileToggle({ currentTarget }) {
    $(currentTarget).toggleClass('navigation-widget__header--active');
    $(currentTarget).siblings('.navigation-widget__content').slideToggle('hidden');
    $(currentTarget).find('.navigation-widget__toggle').toggleClass('navigation-widget__toggle--up navigation-widget__toggle--down');
  },

  navWidgetHeightAdjustment(wait = 200)
  {
    setTimeout(() => {
      if ( $(window).width() > 1179 && (this.$menu.height() - 150) > this.originalArticleHeight ) {
        $('.guide-article').css('min-height', ($('.navigation-widget').height() - 150) + 'px');
      } else {
        $('.guide-article').css('min-height', 'auto');
      }
    }, wait);
  }
};

<template>
    <div class="document-block__arrows">
        <button class="document-block__arrow" @click="moveUp">
            <i class="fal fa-chevron-up"></i>
        </button>
        <button class="document-block__arrow" @click="moveDown">
            <i class="fal fa-chevron-down"></i>
        </button>
    </div>
</template>

<script>
  import { store } from '../store/store';

  export default {
    name: "reorder",
    data() {
      return {
      }
    },
    props: {
      position: Number,
    },
    computed: {
    },
    methods: {
      moveUp() {
        store.mutations.moveContent(this.position, this.position - 1);
      },
      moveDown() {
        store.mutations.moveContent(this.position, this.position + 1);
      }
    },
    beforeMount: function () {
    }
  };
</script>

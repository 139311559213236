import $ from 'jquery';
import Modal from './modal';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.js--delete-profile-modal', this.showDeleteModal.bind(this));
    $(document).on('click', '.js--delete-profile', this.deleteProfile.bind(this));
  },

  showDeleteModal() {
    this.open();
    return false;
  },

  open() {
    const content = $('.modal-delete-profile-holder').html();
    const opts = {
      classes: 'modal--delete-profile',
    };

    const modal = new Modal({
      ...opts,
      content,
    });

    modal.open();
  },

  deleteProfile({ currentTarget })
  {
    event.preventDefault();
    let $target = $(currentTarget);
    $target.addClass('btn--loading');

    this.postDeleteProfile().always((response, status) => {
      if (status === 'success') {
        // Reload to get redirected by backend.
        window.location = response.redirect;
      }
      $target.removeClass('btn--loading');
    });
  },

  postDeleteProfile() {
    return jQuery.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_private_delete_user',
        nonce: window.ajax_object.nonce,
      },
      dataType: 'json',
      method: 'POST',
    });
  },
};


import $ from 'jquery';
import createAccountLabel from '../components/create-account-label';
import debounce from '../util/debounce';
import validator from '../util/validator';

export default {
  components: [
    createAccountLabel,
  ],

  init() {
    this.eventHandlers.self = this;
    this.helpers.self = this;
    this.required = ['first_name', 'last_name','password', 'password_repeat', 'location', 'privacy_terms'];
    this.$form = $('#create-account');
    this.$formWrapper = $('.js--form-wrapper');
    this.$errorWrapper = $('.js--form-error');
    this.$succesWrapper = $('.js--form-success');
    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
    this.$form.on('submit', this.eventHandlers.onSubmit.bind(this));
    this.$form.on('input', 'input', this.eventHandlers.inputChange);
    this.$form.on('blur', 'input', this.eventHandlers.inputBlur.bind(this));
    this.$formWrapper.on('click', '.js--send-activation', this.eventHandlers.sendActivationLink);
  },

  modifyDOM() {
  },

  eventHandlers: {
    inputBlur(e) {
      const $target = $(e.target);
      const data = this.helpers.serializeForm();
      const errors = this.helpers.validate(data);

      // If error in blured input, show error for that input
      if (errors.indexOf($target.attr('name')) !== -1) {
        this.helpers.showErrors([$target.attr('name')]);
      } else {
        this.helpers.showSuccess([$target.attr('name')]);
      }
    },
    inputChange: debounce( (e) => {
        let $target = $(e.target);
        let $wrapper = $target.parent();
        $wrapper.removeClass('error','success');

        if ($wrapper.hasClass('checkbox-wrapper__label')) {
          $wrapper.next('.error__message').remove();
        } else {
          $wrapper.find('.error__message').remove();
        }
    }, 300),

    onSubmit(e) {
      e.preventDefault();
      this.helpers.hideErrors();
      this.helpers.hideFormError();
      this.$form.find('button').addClass('btn--loading');
      const data = this.helpers.serializeForm();
      const errors = this.helpers.validate(data);

      if ( errors.length === 0 ) {
        this.helpers.createUser(data).then((response) => {
          this.$form.find('button').removeClass('btn--loading');

          // No user, we have an error
          if (response.user === false) {
            this.helpers.showErrors([response.field], response.message[0]);
          } else {
            // Clear form
            this.helpers.showFormSuccess(response.message);
          }
        });
      } else {
        this.helpers.showErrors(errors);
        this.$form.find('button').removeClass('btn--loading');
      }
    },
    sendActivationLink(e) {
      e.preventDefault();
      const $target = $(e.target);
      $target.addClass('btn--loading').removeClass('error','success');

      $.ajax({
        url: window.ajax_object.ajax_url,
        data: {
          action: 'mk_send_activation_link',
          user: $target.data('user'),
          nonce: $target.data('nonce'),
        },
        dataType: 'json',
        method: 'POST',
        error: () => {
          $target.addClass('error');
        },
        success: () => {
          $target.addClass('success');
        }
      });

    }
  },

  helpers: {
    serializeForm() {
      let data = this.self.$form.serializeArray();
      const policy = data.filter(d => d.name === 'privacy_terms');

      if (!policy.length) {
        data.push({name: 'privacy_terms', value: ''});
      }

      return data;
    },
    validate(data) {
      let errors = [];
      let password = '';

      data.forEach((field) => {
        if (this.self.required.indexOf(field.name) !== -1 && field.value.length === 0 ) {
          errors.push(field.name);

        }else if (field.name === 'user_email') {
          if (!validator.validateEmail(field.value)) {
            errors.push(field.name);
          }
        } else if (field.name === 'password') {
          password = field.value;

          if (!validator.validatePassword(field.value)) {
            errors.push(field.name);
          }
        } else if (field.name === 'password_repeat' && field.value !== password) {
          errors.push(field.name);
        }
      });

      return errors;
    },
    createUser(data) {
      return $.ajax({
        url: window.ajax_object.ajax_url,
        data: {
          action: 'mk_create_user',
          form_data: data,
        },
        dataType: 'json',
        method: 'POST',
        error: () => {
          this.self.$form.find('button').removeClass('btn--loading');
          this.self.helpers.showFormError( this.self.$errorWrapper.data('generic') );
        }
      });
    },
    showErrors(errors, message = false) {
      errors.forEach((error) => {
        let $input = this.self.$form.find("[name='" + error + "']");
        let $wrapper = $input.parent();

        if (!$wrapper.hasClass('error')) {
          if ($wrapper.hasClass('checkbox-wrapper__label')) {
            $('<span class="error__message"><i class="fal fa-info-circle"></i> ' + (message ? message : $wrapper.data('required')) + '</span>').insertAfter($wrapper);
          } else {
            $('<span class="error__message"><i class="fal fa-info-circle"></i> ' + (message ? message : $wrapper.data('required')) + '</span>').insertAfter($input);
          }
          $wrapper.addClass('error').removeClass('success');
        }
      });

      $([document.documentElement, document.body]).animate({
        scrollTop: $('span.error__message').first().offset().top - 150
      }, 500);
    },
    showSuccess(successes) {
      successes.forEach((success) => {
        let $input = this.self.$form.find("[name='" + success + "']");
        let $wrapper = $input.parent();

        if (!$wrapper.hasClass('success')) {
          $wrapper.addClass('success').removeClass('error');
        }

        if ($wrapper.hasClass('checkbox-wrapper__label')) {
          $wrapper.next('.error__message').remove();
        } else {
          $wrapper.find('.error__message').remove();
        }
      });
    },
    hideErrors() {
      this.self.$form.find('.error__message').remove();
      this.self.$form.find('.error').removeClass('error');
    },
    showFormError(message) {
      this.self.$errorWrapper.find('p').html( message );
      this.self.$errorWrapper.show();
    },
    hideFormError() {
      this.self.$errorWrapper.find('p').html('');
      this.self.$errorWrapper.hide();
    },
    showFormSuccess(content) {
      this.self.$formWrapper.html(content);
    }
  },
};

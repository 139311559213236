<template>
    <div class="mb-16">

        <div v-if="inPreview">
            <p>
                <span>{{source.author1.last_name}}, {{source.author1.first_name_initial}}.</span>
                <span v-if="source.author2.last_name.length > 0">{{source.author2.last_name}}, {{source.author2.first_name_initial}}.</span>
                <span v-if="source.author3.last_name.length > 0">{{source.author3.last_name}}, {{source.author3.first_name_initial}}.</span>
                <span>({{source.year}}). {{source.title}}.</span>
                <span>({{source.date}}). <a :href="source.url" target="_blank">{{source.url}}</a>.</span>
            </p>
        </div>


        <form v-show="inEdit" class="w-full mb-32">
            <h4 class="relative">Internkälla
                <button class="absolute right-0 top-0 mx-auto block hover:underline" @click="remove"><i class="fas fa-trash mr-16 text-md"></i></button>
            </h4>

            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label for="grid-author1">Författare 1 *</label>
                    <input id="grid-author1" type="text" placeholder="Efternamn" v-model="source.author1.last_name" maxlength="200">
                </div>
                <div class="w-full md:w-1/2 px-3">
                    <label class="invisible" for="grid-author1-initial">Initial</label>
                    <input type="text" id="grid-author1-initial" v-model="source.author1.first_name_initial" placeholder="Initial" maxlength="1">
                </div>
                <div class="w-full md:w-1/2 px-3 mb-16">
                    <p v-if="errors.author1 && shouldValidate" class="text-red text-xs italic">{{$lang('documentSourceFormError')}}</p>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label for="grid-author2">Författare 2</label>
                    <input id="grid-author2" type="text" placeholder="Efternamn" v-model="source.author2.last_name" maxlength="200">
                </div>
                <div class="w-full md:w-1/2 px-3">
                    <label class="invisible" for="grid-auhtor2-initial">Initial</label>
                    <input type="text" id="grid-auhtor2-initial" v-model="source.author2.first_name_initial" placeholder="Initial" maxlength="1">
                </div>
                <div class="w-full md:w-1/2 px-3 mb-16">
                    <p v-if="errors.author2 && shouldValidate" class="text-red text-xs italic">{{$lang('documentSourceFormError')}}</p>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-16">
                    <label for="grid-author3">Författare 3</label>
                    <input id="grid-author3" type="text" placeholder="Efternamn" v-model="source.author3.last_name" maxlength="200">
                </div>
                <div class="w-full md:w-1/2 px-3 mb-16">
                    <label class="invisible" for="grid-author3-initial">Initial</label>
                    <input type="text" id="grid-author3-initial" v-model="source.author3.first_name_initial" placeholder="Initial" maxlength="1">
                </div>
                <div class="w-full md:w-1/2 px-3 mb-16">
                    <p v-if="errors.author3 && shouldValidate" class="text-red text-xs italic">{{$lang('documentSourceFormError')}}</p>
                </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-16">
                    <label for="grid-title">Titel*</label>
                    <input id="grid-title" type="text" v-model="source.title" maxlength="200">
                    <p v-if="errors.title && shouldValidate" class="text-red text-xs italic">{{$lang('documentSourceFormError')}}</p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-16">
                    <label for="grid-year">Utgivnings år*</label>
                    <input id="grid-year" type="text" v-model="source.year" maxlength="4">
                    <p v-if="errors.year && shouldValidate" class="text-red text-xs italic">{{$lang('documentSourceFormError')}}</p>
                </div>

                <div class="w-full md:w-1/2 px-3 mb-16">
                    <label class="block" for="grid-date">Datum*</label>
                    <date-picker id="grid-date" :lang="lang" v-model="source.date" valueType="format"></date-picker>
                    <p v-if="errors.date && shouldValidate" class="text-red text-xs italic">{{$lang('documentSourceFormError')}}</p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-16">
                    <label for="grid-url">URL*</label>
                    <input id="grid-url" type="text" v-model="source.url" maxlength="200">
                    <p v-if="errors.url && shouldValidate" class="text-red text-xs italic">{{$lang('documentSourceFormError')}}</p>
                </div>
            </div>

            <div class="w-full md:w-1/2 px-3 mb-16">
                <button type="button" class="btn btn--transparent sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0" @click="onSave">{{$lang('save')}}</button>
            </div>
        </form>
    </div>
</template>

<script>
  import {store} from '../store/store';
  import vueMixins from '../../vue-mixins';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/locale/sv';

  export default {
    name: "internetSource",
    mixins: [vueMixins],
    data() {
      return {
        errors: {
          author1: false,
          author2: false,
          author3: false,
          year: false,
          title: false,
          url: false,
          date: false,
        },
        shouldValidate: false,
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
        },
      }
    },
    props: {
      source: Object,
      view: String,
      position: Number,
      hasError: Boolean,
    },
    components: {
      DatePicker,
    },
    computed: {
      inPreview: function() {
        return this.view === 'preview';
      },
      inEdit: function() {
        return this.view === 'edit';
      },
    },
    watch: {
      hasError: function(newValue) {
        if (newValue) {
          this.shouldValidate = true;
          this.validateData();
        }
      }
    },
    methods: {
      onSave() {
        this.shouldValidate = true;

        if (this.validateData()) {
          this.$emit('view-change', 'preview');
          this.source.isValid = true;
        } else {
          this.source.isValid = false;
        }
      },
      remove() {
        store.mutations.removeSource(this.position);
      },
      validateData() {
        this.errors.author1 = this.source.author1.first_name_initial.length !== 1 || this.source.author1.last_name.length === 0;
        this.errors.author2 = (this.source.author2.first_name_initial.length === 1 && this.source.author2.last_name.length === 0) || (this.source.author2.first_name_initial.length === 0 && this.source.author2.last_name.length > 1);
        this.errors.author3 = (this.source.author3.first_name_initial.length === 1 && this.source.author3.last_name.length === 0) || (this.source.author3.first_name_initial.length === 0 && this.source.author3.last_name.length > 1);
        this.errors.year = this.source.year.length !== 4 || parseInt(this.source.year,10) != this.source.year;
        this.errors.title = this.source.title.length === 0;
        this.errors.url = this.source.url.length === 0;
        this.errors.date = this.source.date.length === 0;

        return Object.keys(this.errors).filter( (error) => {
          return this.errors[error] === true;
        }).length === 0;
      }
    },
    mounted: function () {
    }
  };
</script>

<template>
    <div class="rounded bg-white shadow py-24 p-32 pb-0 mb-24 sm:max-w-3xl md:max-w-full mx-auto md:w-full hidden md:block">
        <tools :position="-1" @add-block="onAddBlock" />
    </div>
</template>

<script>
  import tools from '../parts/part-tools'

  export default {
    name: "sidebar-tools",
    data() {
      return {
      }
    },
    props: {
    },
    components: {
      tools,
    },
    computed: {
    },
    methods: {
      async onAddBlock(tool) {
        this.$emit('add-block', tool);

        await this.$nextTick();
        let element = document.querySelectorAll('.document-block');
        if (element.length) {
          element[element.length - 1].scrollIntoView({behavior: 'smooth'});
        }
      }
    },
    beforeMount: function () {
    }
  };
</script>

import $ from 'jquery';

export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $(document).on('click', '.js-toggle-nav', this.onNavToggleClick.bind(this));
    $(document).on('click', '.js-toggle-subnav', this.onSubNavToggleClick.bind(this));
  },

  modifyDOM() {
    
  },

  onNavToggleClick({ currentTarget }) {
    $('.nav').slideToggle('.nav');
    $(currentTarget).find('i.fa-bars').toggleClass('fa-times');
  },

  onSubNavToggleClick({ currentTarget }) {
    $('.nav__submenu').slideUp();

    if ($(currentTarget).prev('.nav__wrapper').find('.nav__submenu').hasClass('nav_submenu--opened')) {

      $(currentTarget).prev('.nav__wrapper').find('.nav__submenu').slideUp().removeClass('nav_submenu--opened');
      $(currentTarget).removeClass('fa-chevron-up');
      $(currentTarget).parent('.nav__item').removeClass('nav__item--opened');

    } else {

      $('.nav__submenu').removeClass('nav_submenu--opened');
      $('.js-toggle-subnav').removeClass('fa-chevron-up');
    
      $(currentTarget).prev('.nav__wrapper').find('.nav__submenu').slideDown().addClass('nav_submenu--opened');
      $(currentTarget).addClass('fa-chevron-up');
      $(currentTarget).parent('.nav__item').addClass('nav__item--opened');
    }

  },
  
};

<template>
    <div class="document-block document-block--editable" v-bind:class="{'border-red' : componentError}">
        <reorder :position="position"/>
        <remove :position="position"/>
        <blockToolbar :view="view" @view-change="onViewChange"/>

        <div class="document-block__content" v-if="inPreview">
            <div class="guide-article__block  video-section">
                <div class="guide-article__block-content">
                    <section class="guide-article__block-content">
                        <div class="embedded-video embedded-video--full">
                            <div class="embedded-video__placeholder relative" :data-video="component.movie">
                                <div class="bg-orange w-48 h-48 lg:h-56 lg:w-56 flex-shrink-0 flex items-center justify-center rounded-full mr-24 lg:mr-32 absolute transform-xy-50"  @click="onPlayMovie" v-if="!playing">
                                    <i class="fa fa-play text-white ml-4 text-lg"></i>
                                </div>
                                <img :src="component.image" class="sm:block w-full" v-if="!playing">

                                <div class="embedded-video__container relative w-full" v-if="isVimeo && playing">
                                    <iframe :src="videoEmbedUrl" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                                </div>

                                <div class="embedded-video__container relative w-full" v-if="isYouTube && playing">
                                    <iframe width="560" height="315" :src="videoEmbedUrl" allowfullscreen allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                </div>

                            </div>
                        </div>
                        <span class="text-gray-text-light italic text-xs block mt-12">{{component.movieDescription}}</span>
                    </section>
                </div>
            </div>
        </div>

        <form v-show="inEdit">
            <error :has-error="hasError" :message="errorMessage" />

            <label for="image_file">{{$lang('selectMovieImage')}} *</label>
            <input type="file" id="image_file" name="image_file" ref="imageFile" accept="image/png, image/jpeg" v-if="!hasImage" @change="onSetImage" class="pt-12 mb-16"/>

            <div class="relative max-w-sm mb-16 border border-dashed border-gray-border" v-if="hasImage">
                <img v-if="hasImage" v-bind:src="component.image">

                <button class="absolute top-16 right-24 sm:right-16 z-10" type="button" @click="onRemoveImage">
                    <i class="fal fa-times text-2xl sm:text-lg text-gray-text-light"></i>
                </button>
            </div>

            <label for="movie_url">{{$lang('movie')}} *</label>
            <input type="url" id="movie_url" name="movie_url" ref="movieUrl" v-model="component.movie" class="mb-16"/>

            <label for="movie_description">{{$lang('movieCaption')}}</label>
            <div class="relative">
                <input type="text" id="movie_description" name="movie_description" v-model="component.movieDescription" class="mb-16 pr-80">
                <span class="absolute right-8 top-16 text-gray-border">{{descriptionLength}} / 200</span>
            </div>

            <button type="button" class="btn sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0" @click="onUploadMovie" :disabled="loading">{{$lang('add')}}</button>
        </form>

        <loader :is-loading="loading" />
    </div>
</template>

<script>
  import { store } from '../store/store';
  import vueMixins from '../../vue-mixins';
  import reorder from '../parts/part-reorder';
  import remove from '../parts/part-remove';
  import loader from '../parts/part-component-loader';
  import error from '../parts/part-component-error';
  import blockToolbar from '../parts/part-block-toolbar';

  export default {
    name: "componentMovie",
    mixins: [vueMixins],
    data() {
      return {
        file: false,
        loading: false,
        errorMessage: '',
        view: 'edit',
        postID: store.state.document.id,
        playing: false,
      }
    },
    props: {
      component: Object,
      position: Number,
      componentError: Boolean,
    },
    components: {
      reorder,
      remove,
      blockToolbar,
      error,
      loader,
    },
    computed: {
      videoEmbedUrl() {
        if (this.isVimeo) {
          return `https://player.vimeo.com/video/${this.getVimeoID(this.component.movie)}?autoplay=1&title=0&byline=0`;
        } else if (this.isYouTube) {
          return `https://www.youtube.com/embed/${this.getYouTubeID(this.component.movie)}?autoplay=1`;
        }
        return false;
      },
      isVimeo() {
        return (this.component.movie.length > 0 && this.getVimeoID(this.component.movie) !== false);
      },
      isYouTube() {
        return (this.component.movie.length > 0  && this.getYouTubeID(this.component.movie) !== false);
      },
      hasImage: function() {
        return this.component.image.length > 0;
      },
      hasMovie: function () {
        return this.component.movie.length > 0 && (this.isVimeo || this.isYouTube);
      },
      inPreview: function () {
        return this.view === 'preview';
      },
      inEdit: function () {
        return this.view === 'edit';
      },
      descriptionLength: function () {
        return this.component.movieDescription.length;
      },
      hasError: function () {
        return this.errorMessage.length > 0;
      },
    },
    watch: {
      componentError: function (newValue) {
        if (newValue) {
          this.validate();
        }
      },
    },
    methods: {
      getVimeoID(url) {
        let regExp = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
        let match = url.match(regExp);
        return match ? match[3] : false;
      },
      getYouTubeID(url) {
        let regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        let match = url.match(regExp);
        return match ? match[1] : false;
      },
      onViewChange(view) {
        this.view = view;
      },
      onPlayMovie() {
        this.playing = true;
      },
      onUploadMovie() {
        if (!this.validate()) {
          this.loading = false;
          return;
        }

        // If we have a file we need to upload it
        if (this.file) {
          this.loading = true;
          let formData = new FormData();
          formData.append('file', this.file, this.file.name);
          formData.append('image_id', this.component.attachmentID);
          formData.append('action', 'mk_private_upload_image');

          store.actions.uploadFile(formData).always((response, textStatus) => {
            this.loading = false;

            if (textStatus === 'success') {
              this.file = false;
              this.component.image = response.image;
              this.component.attachmentID = response.id;
              this.onViewChange('preview');
            } else {
              this.errorMessage = typeof response.responseJSON === 'undefined' ? this.$lang('genericError') : response.responseJSON.message;
            }
          });
        } else {
          // Image already uploaded, switch to preview
          this.onViewChange('preview');
        }
      },
      onSetImage: function() {
        // Reset error message
        this.errorMessage = '';
        this.component.image = '';
        let file = this.$refs.imageFile.files.length > 0    ?   this.$refs.imageFile.files[0]    :   false;

        if (file && ['image/png', 'image/jpg'].indexOf(file.type) !== -1) {
          this.file = file;
        } else {
          this.file = false;
        }
      },
      onRemoveImage() {
        this.file = '';
        this.component.image = '';
      },
      validate() {
        let valid = (this.file || this.hasImage) && this.hasMovie;

        if (!valid) {
          this.errorMessage = this.$lang('movieSelectionError');
          this.onViewChange('preview');
        } else {
          this.errorMessage = '';
          this.component.isValid = true;
        }

        return valid;
      }
    },
    mounted: function () {
      // Show preview if we have a image
      this.$nextTick(function () {
        // Give this component instance a unique id
        if (this.component.componentID === false) {
          this.component.componentID = `flx_${this.postID}_${new Date().getTime()}`;
        }

        if (this.hasMovie) {
          this.onViewChange('preview');
        }
      });
    }
  };
</script>

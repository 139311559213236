import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.js-open-search', this.openSearch.bind(this));
    $(document).on('click', '.js-close-search', this.openSearch.bind(this));
    $(document).on('click', '.header-search', this.closeSearch.bind(this));
    $(document).on('click', '.js-toggle-nav', this.closeSearchMenuClick.bind(this));
  },

  openSearch(event) {
    event.preventDefault();

    if ($(window).width() < 640) {
      $('.header-search').slideToggle();
    } else {
      $('body').hasClass('header-search-active') ? $('.header-search').fadeOut() : $('.header-search').fadeIn();
      $('.header-search__content').slideToggle();
    }

    $('body').toggleClass('header-search-active');
  },

  closeSearch(event) {

    if ($(window).width() < 640) {
      if ($(event.target).hasClass('js-close-search')) {
        $('body').removeClass('header-search-active');
        $('.header-search').slideUp();
      }
    } else if ($(window).width() > 640) {
        if ($(event.target).hasClass('header-search') ||  $(event.target).hasClass('js-close-search')) {
          $('body').removeClass('header-search-active');
          $('.header-search__content').slideUp();
          $('.header-search').fadeOut();
        }
    }
  },

  closeSearchMenuClick() {
    $('body').removeClass('header-search-active');
    $('.header-search').slideUp();
  },
};

<template>
    <div class="absolute top-0 right-0 bottom-0 left-0 bg-white bg-opacity-75 flex justify-center items-start" v-show="status.isLoading">
        <i class="far fa-circle-notch fa-spin fa-10x text-gray-border m-64"></i>
    </div>
</template>

<script>
  import vueMixins from '../../vue-mixins';
  import { store } from '../store/store';

  export default {
    name: "loader",
    mixins: [vueMixins],
    data() {
      return {
        status: store.state.status,
      }
    },
  };
</script>

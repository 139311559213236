import $ from 'jquery';

export default {
  init() {
    this.addEvents();
    this.ModifyDOM();
  },

  addEvents() {
    $(document).on('click', '.js--bookmark', this.bookmark.bind(this));
  },

  ModifyDOM() {
  },


  bookmark(e) {
    let $btn = $(e.target);
    let isBookmarked = $btn.hasClass('btn--tag-active') ? 1 : 0;

    $btn.find('.fa-bookmark').addClass('hidden');
    $btn.find('.fa-circle-notch').removeClass('hidden').addClass('inline-block');

    this.setBookmark(isBookmarked).always((response, status) => {
      if (status === 'success') {
        isBookmarked === 0 ? $btn.addClass('btn--tag-active') : $btn.removeClass('btn--tag-active');
        $btn.find('.fa-exclamation-triangle, .fa-circle-notch').addClass('hidden');
        $btn.find('.fa-bookmark').removeClass('hidden').addClass('inline-block');
      } else {
        $btn.find('.fa-bookmark, .fa-circle-notch').addClass('hidden');
        $btn.find('.fa-exclamation-triangle').removeClass('hidden').addClass('inline-block');
      }
    });
  },

  setBookmark(data) {
    return $.ajax({
      url: window.ajax_object.ajax_url,
      data: {
        action: 'mk_bookmark',
        is_bookmarked: data,
        post_id: window.ajax_object.post_id,
        nonce: window.ajax_object.nonce,
      },
      dataType: 'json',
      method: 'POST',
    });
  },
};

<template>
    <div class="border border-green bg-green rounded p-12 flex items-center mb-12" v-if="success.hasSuccess">
        <i class="fas fa-check-circle text-white text-2xl mr-8"></i>
        <span class="font-bold text-white text-sm">
            {{success.message}}
        </span>
    </div>
</template>

<script>
  import { store } from '../store/store';

  export default {
    name: "success",
    data() {
      return {
        success: store.state.success,
      }
    },
  };
</script>

<template>
    <div class="editor relative" ref="editor">
        <!--header-->
        <div class="flex justify-between mb-24 flex-wrap sm:flex-no-wrap">
            <a :href="links.my_documents" class="text-orange font-bold mb-24 sm:mb-0 sm:pt-16">
                <i class="fa fa-chevron-left text-xxs mr-8"></i> {{$lang('toMyDocuments')}}
            </a>
            <div class="w-full flex sm:inline-block sm:w-auto xsOnly:flex-wrap">
                <a :href="links.preview" target="_blank"
                        class="btn btn--transparent sm:mr-16 md:mr-24 font-normal px-0 sm:px-24 md:px-32 w-1/2 sm:w-auto mr-12 xsOnly:w-full xsOnly:mr-0 xsOnly:mb-16">
                    {{$lang('preview')}}
                </a>
                <button class="btn sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0"
                        @click="onSave">{{$lang('save')}}
                </button>
            </div>
        </div>

        <!--main-->
        <div class="md:flex">
            <div class="md:w-9/12 mx-auto md:pr-24">
                <div class="sm:bg-white sm:rounded-lg sm:mx-0 sm:p-32">

                    <success />
                    <error />

                    <input class="border-gray-border-light sm:text-lg md:text-xl mb-24" :placeholder="$lang('title')"
                           v-model="document.post_title">

                    <div class="tiny tiny--stripped mb-12 md:mb-24">
                        <tiny-editor api-key="no-api-key" id="tiny-description" v-model="document.post_content" :init="{
                        menubar: false,
                        toolbar: false,
                        resize: false,
                        content_css: links.content_css,
                        }"/>
                    </div>

                    <span class="italic text-gray-text-light text-xs block mb-56 md:hidden">{{$lang('disclaimer')}}</span>

                    <slot v-for="(component, index) in document.flexible_content">
                        <component :is="component.type" :component="component" :key="index" :position="parseInt(index,10)" :component-error="invalidComponents.indexOf(component.componentID) !== -1 && status.isValidating"/>
                        <addBlock :position="parseInt(index,10) + 1"  @add-block="onAddBlock"/>
                    </slot>

                    <addBlock :position="-1" v-if="document.flexible_content.length === 0" @add-block="onAddBlock"/>
                    <theSource :component="document.document_sources" :sources-with-error="invalidSources"/>
                </div>
            </div>

            <!--sidebar-->
            <div class="mt-32 md:mt-0 md:w-4/12 lg:w-3/12">
                <sidebarSettings/>
                <sidebarPreview :preview="links.preview" @save="onSave" />
                <sidebarTools @add-block="onAddBlock"/>
                <span class="italic text-gray-text-light text-xs hidden md:block">{{$lang('disclaimer')}}</span>
            </div>
        </div>

        <loader/>
    </div>
</template>

<script>
  import vueMixins from '../vue-mixins';
  import sidebarTools from './sidebars/sidebar-tools';
  import sidebarSettings from './sidebars/sidebar-settings';
  import sidebarPreview from './sidebars/sidebar-preview';
  import handbook from './components/component-handbook';
  import document from './components/component-document';
  import componentText from './components/component-text';
  import componentImage from './components/component-image';
  import componentMovie from './components/component-movie';
  import componentFile from './components/component-file';
  import addBlock from './parts/part-add-block';
  import loader from './parts/part-loader'
  import error from './parts/part-error';
  import success from './parts/part-success';
  import {store} from './store/store';
  import editor from '@tinymce/tinymce-vue'
  //https://www.tiny.cloud/docs/integrations/vue/#tinymcevuejsintegrationquickstartguide
  import source from './components/component-source';

  export default {
    name: "editor",
    mixins: [vueMixins],
    props: {},
    components: {
      sidebarSettings,
      sidebarTools,
      sidebarPreview,
      handbook,
      document,
      componentText,
      componentImage,
      componentMovie,
      componentFile,
      addBlock,
      loader,
      error,
      success,
      'tiny-editor': editor,
      'theSource': source,
    },
    data() {
      return {
        document: store.state.document,
        links: store.state.links,
        nonce: store.state.nonce,
        status: store.state.status,
      }
    },
    computed: {
      invalidComponents: function() {
        return this.document.flexible_content.filter( (flx) => {
          return flx.isValid === false;
        }).map( (flx) => {
          return flx.componentID;
        });
      },
      invalidSources: function() {
        return this.document.document_sources.filter( (ds) => {
          return ds.isValid === false;
        }).map( (ds) => {
          return ds.sourceID;
        });
      },
      componentsValid: function() {
        return this.invalidComponents.length === 0;
      },
      sourcesValid: function() {
        return this.invalidSources.length === 0;
      },
    },
    watch: {
      document: {
        deep: true,
        handler(newData) {
          if (newData.post_content === '') {
            this.document.post_content = '<p class="placeholder">' + this.$lang('documentDescription') + '</p>';
          }
          store.mutations.success('', false);
          store.mutations.dirty(true);
        }
      },
    },
    methods: {
      validate() {
        let valid = true;
        store.mutations.validating(true);

        if (this.document.post_title.length === 0) {
          store.mutations.error(this.$lang('titleError'), true);
          valid = false;
        } else if (this.document.target_group === 'false' || this.document.target_group === false) {
          store.mutations.error(this.$lang('targetGroupError'), true);
          valid = false;
        } else if (this.document.metal === 'false'  || this.document.metal === false) {
          store.mutations.error(this.$lang('areaError'), true);
          valid = false;
        } else if (!this.componentsValid) {
          store.mutations.error(this.$lang('componentsError'), true);
          valid = false;
        } else if (!this.sourcesValid) {
          store.mutations.error(this.$lang('sourcesError'), true);
          valid = false;
        }

        return valid;
      },
      onSave() {
        store.mutations.loading(true);
        store.mutations.error('', false);

        if (this.validate()) {
          store.actions.saveDocument().always((response, textStatus) => {

            if (textStatus === 'success' && response.success === true) {
              store.mutations.success(this.$lang('saveSuccess'), true);
            } else {
              store.mutations.error(this.$lang('saveError'), true);
            }

            store.mutations.loading(false);
            store.mutations.dirty(false);
            store.mutations.validating(false);
          });
        } else {
          store.mutations.loading(false);
        }
      },
      onAddBlock(block) {

        switch (block.type) {
          case 'text':
            store.mutations.addContent(block.position, {postID: this.document.parent_id, componentID: false, sourceType: 'self', type: 'componentText', title: '', content: '', isValid: false});
            break;
          case 'image':
            store.mutations.addContent(block.position, {postID: this.document.parent_id, componentID: false, sourceType: 'self', type: 'componentImage', attachmentID: false, image: '', imageDescription: '', isValid: false});
            break;
          case 'movie':
            store.mutations.addContent(block.position, {postID: this.document.parent_id, componentID: false, sourceType: 'self', type: 'componentMovie', movie: '', movieDescription: '',  image: '',  attachmentID: false, isValid: false});
            break;
          case 'document':
            store.mutations.addContent(block.position, {postID: this.document.parent_id, componentID: false, sourceType: 'self', type: 'componentFile', attachmentID: false, document: '', documentDescription: '', isValid: false});
            break;
        }
      },
    },
    mounted: function () {
      window.addEventListener("beforeunload", (event) => {
        if (this.status.isDirty) {
          event.returnValue = this.$lang('leavingDirty'); // Only IE supports custom messages...
        } else {
          delete event['returnValue'];
        }
      });
    }
  }
</script>

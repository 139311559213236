<template>
    <div class="document-block document-block--editable" v-bind:class="{'border-red' : componentError}">
        <reorder :position="position" />
        <remove :position="position" />
        <blockToolbar :view="view" @view-change="onViewChange" />

        <div class="document-block__content">
            <div class="guide-article__block document-section" v-show="inPreview">
                <div class="guide-article__block-content">

                    <a :href="component.document"><i class="fas fa-file-pdf text-red"></i></a>
                    <a :href="component.document" class="text-red">{{$lang('download')}}</a>

                    <p class="text-gray-text-light italic text-xs block mt-12">{{component.documentDescription}}</p>
                </div>
            </div>
        </div>

        <form v-show="inEdit">
            <error :has-error="hasError" :message="errorMessage" />

            <label for="document_file">{{$lang('selectDocument')}} *</label>
            <input type="file" id="document_file" name="document_file" ref="documentFile" accept="application/pdf" v-if="!hasDocument" @change="onSetDocument" class="pt-12 mb-16"/>

            <div class="relative max-w-sm mb-16 border border-dashed border-gray-border p-6" v-if="hasDocument">
                <i class="fas fa-file-pdf fa-5x text-red"></i>

                <span>{{ filename }}</span>

                <button class="absolute top-16 right-24 sm:right-16 z-10" type="button" @click="onRemoveDocument">
                    <i class="fal fa-times text-2xl sm:text-lg text-gray-text-light"></i>
                </button>
            </div>

            <label for="document_description">{{$lang('documentCaption')}}</label>
            <div class="relative">
                <input type="text" id="document_description" name="document_description" v-model="component.documentDescription" class="mb-16 pr-80">
                <span class="absolute right-8 top-16 text-gray-border">{{descriptionLength}} / 200</span>
            </div>

            <button type="button" class="btn sm:px-24 md:px-32 w-1/2 sm:w-auto ml-12 sm:ml-0 xsOnly:w-full xsOnly:ml-0" @click="onUploadDocument" :disabled="loading">{{$lang('add')}}</button>
        </form>

        <loader :is-loading="loading" />
    </div>
</template>

<script>
  import { store } from '../store/store';
  import vueMixins from '../../vue-mixins';
  import reorder from '../parts/part-reorder';
  import remove from '../parts/part-remove';
  import blockToolbar from '../parts/part-block-toolbar';
  import loader from '../parts/part-component-loader'
  import error from '../parts/part-component-error'

  export default {
    name: "componentFile",
    mixins: [vueMixins],
    data() {
      return {
        file: false,
        loading: false,
        errorMessage: '',
        view: 'edit',
        postID: store.state.document.id,
      }
    },
    props: {
      component: Object,
      position: Number,
      componentError: Boolean,
    },
    components: {
      reorder,
      remove,
      blockToolbar,
      error,
      loader,
    },
    computed: {
      filename: function() {
        if (this.component.document.length < 1) {
          return '';
        }

        return this.component.document.split('/').pop();
      },
      descriptionLength: function() {
        return this.component.documentDescription.length;
      },
      hasDocument: function() {
        return this.component.document.length > 0;
      },
      inPreview: function() {
        return this.view === 'preview';
      },
      inEdit: function() {
        return this.view === 'edit';
      },
      hasError: function() {
        return this.errorMessage.length > 0;
      },
    },
    watch: {
      componentError: function (newValue) {
        if (newValue) {
          this.validate();
        }
      },
      descriptionLength: function(newValue) {
        if (newValue > 200) {
          this.component.documentDescription = this.component.documentDescription.substr(0, 200);
        }
      }
    },
    methods: {
      onRemoveDocument() {
        this.file = '';
        this.component.document = '';
      },
      onViewChange(view) {
        this.view = view;
      },
      onSetDocument: function() {
        // Reset error message
        this.errorMessage = '';
        this.component.document = '';
        let file = this.$refs.documentFile.files.length > 0    ?   this.$refs.documentFile.files[0]    :   false;

        if (file && ['application/pdf'].indexOf(file.type) !== -1) {
          this.file = file;
        } else {
          this.file = false;
        }
      },
      onUploadDocument: function() {
        this.loading = true;

        if (this.validate()) {
          let formData = new FormData();

          if (this.file) {
            formData.append('file', this.file, this.file.name);
          }

          formData.append('document_description', this.component.documentDescription );
          formData.append('document_id', this.component.attachmentID );
          formData.append('action', 'mk_private_upload_document');

          store.actions.uploadFile( formData ).always((response, textStatus) => {
            this.loading = false;

            if (textStatus === 'success' && response.id) {
              this.component.document = response.document;
              this.component.documentDescription = response.documentDescription;
              this.component.attachmentID = response.id;
            }

            if (textStatus === 'success') {
              this.onViewChange('preview');
            }

            if (textStatus !== 'success') {
              this.errorMessage = typeof response.responseJSON === 'undefined' ? this.$lang('genericError') : response.responseJSON.message;
            }
          });
        } else {
          this.loading = false;
        }
      },
      validate() {
        let valid = this.file || this.hasDocument;

        if (!valid) {
          this.errorMessage = this.$lang('documentSelectionError');
          this.inEdit();
        } else {
          this.errorMessage = '';
          this.component.isValid = true;
        }

        return valid;
      }
    },
    mounted: function () {

      this.$nextTick(function () {
        // Give this component instance a unique id
        if (this.component.componentID === false) {
          this.component.componentID = `flx_${this.postID}_${new Date().getTime()}`;
        }
        // Show preview if we have a document
        if (this.hasDocument) {
          this.onViewChange('preview');
        }
      });
    }
  };
</script>

import $ from 'jquery';
import Modal from './modal';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.js-delete-document-modal', this.showDeleteModal.bind(this));
  },

  showDeleteModal() {
    this.open();
    return false;
  },

  open() {
    const content = $('.modal-delete-document-holder').html();
    const opts = {
      classes: 'modal--delete-document',
    };

    const modal = new Modal({
      ...opts,
      content,
    });

    modal.open();
  },
};


import 'es6-promise/auto';
import $ from 'jquery';
import Router from './util/router';
import common from './routes/common';
import Handbook from './routes/handbook';
import Video from './routes/video';
import Startpage from './routes/startpage';
import CreateAccount from './routes/create-account';
import MyPages from './routes/my-pages';
import Editor from './routes/editor';
import MetalArchive from './routes/metal-archive';
import Document from './routes/document';
import DiscoverArticle from './routes/dicsover-article'
import DiscoverArticleArchive from './routes/discover-article-archive';
import About from './routes/about';
import searchResults from './routes/search-results';

const routes = {
  common,
  single_handbook: Handbook,
  single_video: Video,
  post_type_archive_video: Video,
  page_template_template_startpage: Startpage,
  page_template_template_create_account: CreateAccount,
  page_template_template_my_pages: MyPages,
  page_template_template_my_pages_bookmarks: MyPages,
  page_template_template_my_pages_editor: Editor,
  page_template_template_metal_archive: MetalArchive,
  single_document: Document,
  single_discover_article: DiscoverArticle,
  post_type_archive_discover_article: DiscoverArticleArchive,
  page_template_template_about_us: About,
  search_results: searchResults,
};

$(document).ready(() => new Router(routes).loadEvents());

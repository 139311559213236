import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.radio-wrapper--choose-title .radio-wrapper__label', this.changeInputLabel.bind(this));
  },

  changeInputLabel( {currentTarget} ) {
    var title = $(currentTarget).attr('data-title');
    $('.create-account-input-label').text(title);
  },
};

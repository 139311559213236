export default {
  methods: {
    $lang: (key, params = {}) => {
      let str = lang[key];

      if (!str) {
        return '';
      }

      Object.entries(params).map(([pKey, pValue]) => {
        str = str.replace(`{{${pKey}}}`, pValue);
      });

      return str;
    },
  }
};
